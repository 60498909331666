import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IconButton, Modal, Box, Typography, Button } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import ImageSlider from "./ImageSlider";
import { CurrencyContext } from "../../context/CurrencyContext";
import DownloadIcon from "@mui/icons-material/Download"; // Icono de descarga

const ProductDetail = ({ product, syscomData }) => {
  const [openStockModal, setOpenStockModal] = useState(false);
  const { currency, tipoCambio, incrementoPrecio } =
    useContext(CurrencyContext);

  const totalStock = syscomData?.total_existencia || 0;

  const getPrice = () => {
    const precioLista = syscomData.precios?.precio_descuento || 0;
    return currency === "MXN"
      ? (precioLista * tipoCambio * incrementoPrecio).toFixed(2)
      : (precioLista * incrementoPrecio).toFixed(2);
  };

  const renderStockDetail = () => {
    const stockDetail = syscomData?.existencia?.detalle || [];
    return stockDetail.length > 0
      ? stockDetail.map((location, index) => (
          <li key={index} className="detail-item">
            <strong>{location.almacen}</strong>: {location.cantidad}
          </li>
        ))
      : "No hay detalles de almacén disponibles";
  };

  return (
    <div className="product-detail-container">
      <Link to="/" className="go-back-button">
        &larr; Volver a los resultados
      </Link>

      {product ? (
        <>
          <div className="product-main-content">
            <div className="product-image-container">
              {/* Logo de la marca encima de la foto del producto */}
              {syscomData.marca_logo && (
                <img
                  src={syscomData.marca_logo}
                  alt="Logo de la marca"
                  className="brand-logo"
                />
              )}
              {syscomData?.imagenes && syscomData.imagenes.length > 0 ? (
                <ImageSlider images={syscomData.imagenes} />
              ) : (
                <img
                  src={product["Imagen Principal"]}
                  alt={product.Título}
                  className="product-image"
                />
              )}
            </div>

            <div className="product-info">
              <h1 className="product-title">{product.Título}</h1>
              <p className="product-model">
                <strong>Modelo:</strong> {product.Modelo}
              </p>
              <p className="product-brand">
                <strong>Marca:</strong> {syscomData.marca || product.Marca}
              </p>
              <div className="delivery-info">
                <IconButton color="primary" size="large">
                  <LocalShippingIcon fontSize="large" />
                </IconButton>
                <span className="delivery-text">Envío GRATIS</span>
              </div>

              <div className="stock-summary">
                <IconButton
                  color="primary"
                  onClick={() => setOpenStockModal(true)}
                >
                  <InventoryIcon fontSize="large" />
                </IconButton>
                <span className="stock-text">
                  Stock total: {totalStock} unidades
                </span>
              </div>

              <p className="product-price">
                <strong>Precio:</strong> ${getPrice()} {currency}
              </p>
              <Typography className="iva-included">IVA incluido</Typography>
              <button className="add-to-cart-button">Agregar al carrito</button>
              <button className="buy-now-button">Comprar ahora</button>

              <div className="details-container">
                <h2 className="details-subtitle">Acerca de este artículo</h2>
                <ul className="details-list">
                  {syscomData.caracteristicas &&
                    syscomData.caracteristicas.map((caracteristica, index) => (
                      <li key={index} className="detail-item">
                        {caracteristica}
                      </li>
                    ))}
                </ul>
              </div>

              <div className="details-container">
                <h2 className="details-subtitle">Categorías</h2>
                <ul className="details-list">
                  {syscomData.categorias &&
                    syscomData.categorias.map((categoria) => (
                      <li key={categoria.id} className="detail-item">
                        {categoria.nombre} (Nivel {categoria.nivel})
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <Modal open={openStockModal} onClose={() => setOpenStockModal(false)}>
            <Box className="stock-modal">
              <Typography variant="h6" component="h2" className="modal-title">
                Stock detallado por sucursal
              </Typography>
              <ul className="stock-detail-list">{renderStockDetail()}</ul>
            </Box>
          </Modal>

          {syscomData.descripcion && (
            <div className="long-description-container">
              <h2 className="long-description-title">
                Descripción del producto
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: syscomData.descripcion }}
                className="long-description-content"
              ></div>
            </div>
          )}

          {/* Recursos adicionales */}
          {syscomData.recursos && syscomData.recursos.length > 0 && (
            <div className="recursos-container">
              <h2 className="details-subtitle">Recursos adicionales</h2>
              <ul className="recursos-list">
                {syscomData.recursos.map((recurso, index) => (
                  <li key={index} className="recurso-item">
                    <Button
                      variant="contained"
                      color="primary"
                      href={recurso.path}
                      target="_blank"
                      startIcon={<DownloadIcon />}
                      className="download-button"
                    >
                      {recurso.recurso}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <div className="no-product-message">No se encontró el producto.</div>
      )}

      <style jsx>{`
        .product-detail-container {
          padding: 20px;
          font-family: "Arial", sans-serif;
          background-color: #ffffff;
          min-height: 100vh;
          color: #111;
        }

        .go-back-button {
          display: inline-block;
          padding: 8px 12px;
          margin-bottom: 20px;
          background-color: #f0f0f0;
          color: #111;
          border-radius: 4px;
          text-decoration: none;
          font-size: 14px;
          transition: background-color 0.2s ease;
        }

        .go-back-button:hover {
          background-color: #e0e0e0;
        }

        .product-main-content {
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-width: 1200px;
          margin: 0 auto;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
          padding: 20px;
        }

        @media (min-width: 768px) {
          .product-main-content {
            flex-direction: row;
          }
        }

        .product-image-container {
          flex: 1;
          text-align: center;
          position: relative;
        }

        .brand-logo {
          position: absolute;
          top: 0;
          right: 0;
          max-width: 100px;
          height: auto;
          margin: 10px;
        }

        .product-image {
          max-width: 100%;
          height: auto;
          max-height: 500px;
          object-fit: contain;
        }

        .product-info {
          flex: 1;
        }

        .product-title {
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 10px;
          color: #0f1111;
        }

        .product-model,
        .product-brand {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 10px;
          color: #0f1111;
        }

        .delivery-info,
        .stock-summary {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
        }

        .delivery-text,
        .stock-text {
          font-size: 16px;
          font-weight: 500;
          margin-left: 8px;
        }

        .delivery-text {
          color: #007600;
        }

        .product-price {
          font-size: 24px;
          font-weight: 600;
          color: #b12704;
        }

        .iva-included {
          font-size: 12px;
          color: #666;
          margin-top: 5px;
        }

        .add-to-cart-button,
        .buy-now-button {
          width: 100%;
          padding: 10px;
          font-size: 14px;
          border-radius: 20px;
          cursor: pointer;
          margin-bottom: 10px;
          font-weight: bold;
          color: #111;
          text-transform: uppercase;
          transition: background-color 0.2s ease;
        }

        .add-to-cart-button {
          background-color: #ffd814;
          border: 1px solid #fcd200;
        }

        .add-to-cart-button:hover {
          background-color: #f7ca00;
        }

        .buy-now-button {
          background-color: #ffa41c;
          border: 1px solid #ff8f00;
        }

        .buy-now-button:hover {
          background-color: #fa8900;
        }

        .details-container {
          margin-top: 20px;
        }

        .details-subtitle {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .details-list {
          list-style-type: disc;
          padding-left: 20px;
        }

        .detail-item {
          font-size: 14px;
          margin-bottom: 5px;
        }

        .long-description-container {
          max-width: 100%;
          margin: 20px auto 0;
          background-color: #ffffff;
          border-radius: 8px;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
          padding: 20px;
        }

        .long-description-title {
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 15px;
          color: #0f1111;
          text-align: center;
        }

        .long-description-content {
          font-size: 14px;
          line-height: 1.6;
          color: #333;
          text-align: justify;
        }

        .long-description-content img,
        .long-description-content iframe {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 10px auto;
        }

        .stock-modal {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-width: 400px;
          background-color: #fff;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 20px;
          border-radius: 10px;
        }

        .modal-title {
          margin-bottom: 15px;
        }

        .stock-detail-list {
          list-style-type: none;
          padding: 0;
        }

        .recursos-container {
          margin-top: 20px;
        }

        .recursos-list {
          list-style-type: none;
          padding: 0;
        }

        .recurso-item {
          margin-bottom: 10px;
        }

        .download-button {
          display: flex;
          align-items: center;
        }

        .no-product-message {
          text-align: center;
          font-size: 18px;
          color: #666;
          margin-top: 40px;
        }

        @media (max-width: 768px) {
          .product-main-content {
            flex-direction: column;
          }

          .product-image-container,
          .product-info {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default ProductDetail;
