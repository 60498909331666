import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAEG40LahYZ4ZwYSGePRanVxUDvCu3_3JA",
  authDomain: "radiosistemas-a46cd.firebaseapp.com",
  projectId: "radiosistemas-a46cd",
  storageBucket: "radiosistemas-a46cd.appspot.com",
  messagingSenderId: "167959376001",
  appId: "1:167959376001:web:eaf56c7b98e3a6c86e1d80",
  measurementId: "G-Q59GW8BMT3",
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export { auth, db, storage, messaging, analytics, app as default };
