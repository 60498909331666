import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  LinearProgress,
  Button,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import RouterIcon from "@mui/icons-material/Router";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import { db } from "../../../firebase"; // Asegúrate de que la ruta es correcta
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  getDocs,
  limit,
} from "firebase/firestore";
import moment from "moment";
import BatteryChart from "./ReceiverCharts/BatteryChart";
import TemperatureChart from "./ReceiverCharts/TemperatureChart";
import Pings from "./ReceiverCharts/Pings";
import "chart.js/auto";

const convertVoltage = (vsysVoltage) => {
  // Realiza la conversión del voltaje
  return (vsysVoltage * 4.13) / 0.5820207;
};

const calculateBatteryPercentage = (voltage) => {
  // Supongamos que 4.2V es 100% y 3.0V es 0%
  const minVoltage = 3.0;
  const maxVoltage = 4.2;

  if (voltage >= maxVoltage) {
    return 100;
  } else if (voltage <= minVoltage) {
    return 0;
  } else {
    return ((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100;
  }
};

const fixedCoordinates = "40.7128, -74.0060";

const ReceiverInfo = () => {
  const { uid } = useParams();
  const [receiver, setReceiver] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReceiverInfo = async () => {
      const receiverDoc = await getDoc(doc(db, "Receivers", uid));

      if (receiverDoc.exists()) {
        const receiverData = receiverDoc.data();

        const pingsCollection = collection(db, `Receivers/${uid}/Pings`);
        const latestPingQuery = query(
          pingsCollection,
          orderBy("time", "desc"),
          limit(1)
        );

        const latestPingSnapshot = await getDocs(latestPingQuery);

        if (!latestPingSnapshot.empty) {
          const latestPing = latestPingSnapshot.docs[0].data();
          latestPing.vsysVoltage = convertVoltage(latestPing.vsysVoltage);
          latestPing.batteryPercentage = calculateBatteryPercentage(
            latestPing.vsysVoltage
          );
          receiverData.latestPing = latestPing;

          receiverData.isConnected = true;
          receiverData.secondsSinceLastPing = 0;
        } else {
          receiverData.isConnected = false;
          receiverData.secondsSinceLastPing = null;
        }

        const allPingsQuery = query(pingsCollection, orderBy("time", "desc"));
        const allPingsSnapshot = await getDocs(allPingsQuery);
        const pingsData = allPingsSnapshot.docs.map((doc) => doc.data());

        receiverData.chartData = {
          battery: {
            labels: pingsData.map((ping) =>
              moment(ping.time).format("MM-DD HH:mm")
            ),
            datasets: [
              {
                label: "Battery Percentage",
                data: pingsData.map((ping) =>
                  calculateBatteryPercentage(convertVoltage(ping.vsysVoltage))
                ),
                fill: true,
                borderColor: "#4caf50",
                backgroundColor: "rgba(76, 175, 80, 0.1)",
                tension: 0.4,
              },
            ],
          },
          temperature: {
            labels: pingsData.map((ping) =>
              moment(ping.time).format("MM-DD HH:mm")
            ),
            datasets: [
              {
                label: "Temperature (°C)",
                data: pingsData.map((ping) => ping.temperature),
                fill: true,
                borderColor: "#f44336",
                backgroundColor: "rgba(244, 67, 54, 0.1)",
                tension: 0.4,
              },
            ],
          },
        };

        receiverData.pingsData = pingsData;

        setReceiver(receiverData);
      } else {
        setReceiver(null);
      }
      setLoading(false);
    };

    fetchReceiverInfo();
  }, [uid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (receiver?.latestPing) {
        const now = moment();
        const pingTime = moment(receiver.latestPing.time);
        const diffSeconds = now.diff(pingTime, "seconds");

        setReceiver((prevReceiver) => ({
          ...prevReceiver,
          isConnected: diffSeconds <= 20,
          secondsSinceLastPing: diffSeconds,
        }));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [receiver]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!receiver) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6">Receiver no encontrado</Typography>
      </Box>
    );
  }

  const handleRestart = () => {
    console.log("Reiniciando receptor...");
  };

  const handleShutdown = () => {
    console.log("Apagando receptor...");
  };

  return (
    <Box
      p={4}
      sx={{ backgroundColor: "#ffffff", minHeight: "100vh", color: "#000" }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#000",
          fontWeight: "bold",
          mb: 6,
          textTransform: "uppercase",
          letterSpacing: "0.1em",
        }}
      >
        Monitoreo
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={handleRestart}
          sx={{ backgroundColor: "#007BFF" }}
        >
          Reiniciar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<PowerSettingsNewIcon />}
          onClick={handleShutdown}
          sx={{ backgroundColor: "#DC3545" }}
        >
          Apagar
        </Button>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#f5f5f5",
              color: "#000",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(4px)",
              borderRadius: "16px",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <RouterIcon fontSize="large" sx={{ color: "#000" }} />
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", letterSpacing: "0.05em" }}
                >
                  {receiver.stage}
                </Typography>
                <FiberManualRecordIcon
                  sx={{
                    color: receiver.isConnected ? "#4caf50" : "#f44336",
                    position: "absolute",
                    top: 22,
                    left: 202,
                  }}
                />
              </Box>

              <Typography variant="body1" sx={{ mb: 2, opacity: 0.8 }}>
                Posición: {receiver.stage}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <BatteryChargingFullIcon sx={{ mr: 1, color: "#4caf50" }} />
                <Typography variant="body2">
                  Batería: {Math.round(receiver.latestPing.batteryPercentage)}%
                </Typography>
              </Box>
              <Box sx={{ width: "100%", mb: 3 }}>
                <LinearProgress
                  variant="determinate"
                  value={receiver.latestPing.batteryPercentage}
                  sx={{
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#e0e0e0",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor:
                        receiver.latestPing.batteryPercentage > 50
                          ? "#4caf50"
                          : receiver.latestPing.batteryPercentage > 20
                          ? "#ff9800"
                          : "#f44336",
                    },
                  }}
                />
              </Box>
              {receiver.solarPanel && (
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <SolarPowerIcon sx={{ mr: 1, color: "#ffeb3b" }} />
                  <Typography variant="body2">
                    Voltaje Solar: {receiver.latestPing.vsysVoltage.toFixed(1)}V
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <SignalWifi4BarIcon sx={{ mr: 1, color: "#000" }} />
                <Typography variant="body2">
                  Conexión:{" "}
                  {receiver.isConnected ? "Conectado" : "Desconectado"}
                </Typography>
                <Tooltip
                  title={receiver.isConnected ? "Conectado" : "Desconectado"}
                >
                  <IconButton size="small">
                    <CircularProgress
                      size={24}
                      thickness={5}
                      variant="determinate"
                      value={receiver.isConnected ? 100 : 0}
                      sx={{
                        color: receiver.isConnected ? "#4caf50" : "#f44336",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1, color: "#000" }} />
                <Typography variant="body2">
                  Coordenadas: {fixedCoordinates}
                </Typography>
              </Box>
              {receiver.latestPing && (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <ThermostatIcon
                      sx={{
                        mr: 1,
                        color:
                          receiver.latestPing.temperature < 30
                            ? "#4caf50"
                            : receiver.latestPing.temperature < 50
                            ? "#ff9800"
                            : "#f44336",
                      }}
                    />
                    <Typography variant="body2">
                      Temperatura: {receiver.latestPing.temperature} °C
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Último ping:{" "}
                    {moment(receiver.latestPing.time).format("MM-DD HH:mm")}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Voltaje: {receiver.latestPing.vsysVoltage.toFixed(2)} V
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Segundos desde el último ping:{" "}
                    {receiver.secondsSinceLastPing}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: "#f5f5f5",
              color: "#000",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(4px)",
              borderRadius: "16px",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Gráficas de Monitoreo
              </Typography>
              <Box sx={{ p: 2 }}>
                {receiver.chartData && receiver.chartData.battery && (
                  <BatteryChart chartData={receiver.chartData.battery} />
                )}
                {receiver.chartData && receiver.chartData.temperature && (
                  <TemperatureChart
                    chartData={receiver.chartData.temperature}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card
            sx={{
              backgroundColor: "#f5f5f5",
              color: "#000",
              boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur(4px)",
              borderRadius: "16px",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Logs
              </Typography>
              <Box sx={{ p: 2 }}>
                {receiver.pingsData && <Pings pingsData={receiver.pingsData} />}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReceiverInfo;
