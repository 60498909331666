import React, { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  Typography,
  Avatar,
  Box,
  Grid,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import styled from "@emotion/styled";
import Flag from "react-world-flags";

// Styled components
const ProfileContainer = styled(Box)`
  max-width: 100%;
  padding: ${(props) => (props.isMobile ? "16px" : "30px 20px")};
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const StatBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
`;

const ActionButton = styled(Button)`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  margin-bottom: 16px;
  &:hover {
    background-color: ${(props) =>
      props.variant === "contained" ? "#0050b3" : "#e0e0e0"};
  }
`;

// Function to convert country name to country code
const getCountryCode = (country) => {
  const countryCodes = {
    Mexico: "MEX",
    // Add more country mappings here as needed
  };
  return countryCodes[country] || null;
};

const ProfileStats = () => {
  const { uid } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [profileUser, setProfileUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ridesCount, setRidesCount] = useState(0);
  const [friendsCount, setFriendsCount] = useState(0);
  const [friendRequestSent, setFriendRequestSent] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [showLoginWarning, setShowLoginWarning] = useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToUser(uid);
    return () => unsubscribe();
  }, [uid, user?.uid]);

  const subscribeToUser = (userId) => {
    const userRef = doc(db, "Users", userId);
    return onSnapshot(userRef, (userSnap) => {
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setProfileUser(userData);
        setFriendsCount(userData.friends?.length || 0);
        setFriendRequestSent(
          userData.friendRequests?.includes(user?.uid) || false
        );
        setIsFriend(userData.friends?.includes(user?.uid) || false);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const fetchRidesCount = async () => {
      const ridesRef = collection(db, "Rides");
      const q = query(ridesRef, where("riders", "array-contains", uid));
      const querySnapshot = await getDocs(q);
      setRidesCount(querySnapshot.size);
    };
    fetchRidesCount();
  }, [uid]);

  const handleAddFriend = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        await updateDoc(userRef, {
          friendRequests: arrayUnion(user.uid),
        });
        setFriendRequestSent(true);
      } catch (error) {
        console.error("Error adding friend: ", error);
      }
    }
  };

  const handleCancelFriendRequest = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        await updateDoc(userRef, {
          friendRequests: arrayRemove(user.uid),
        });
        setFriendRequestSent(false);
      } catch (error) {
        console.error("Error cancelling friend request: ", error);
      }
    }
  };

  const handleFollow = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        const currentUserRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          friends: arrayUnion(user.uid),
        });
        await updateDoc(currentUserRef, {
          friends: arrayUnion(uid),
        });
        setIsFriend(true);
        setFriendRequestSent(false);
      } catch (error) {
        console.error("Error following user: ", error);
      }
    }
  };

  const handleUnfollow = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        const currentUserRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          friends: arrayRemove(user.uid),
        });
        await updateDoc(currentUserRef, {
          friends: arrayRemove(uid),
        });
        setIsFriend(false);
      } catch (error) {
        console.error("Error unfollowing user: ", error);
      }
    }
  };

  const handleSettingsClick = () => {
    navigate("/addProfile");
  };

  const handleLoginWarningClose = () => {
    setShowLoginWarning(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profileUser) {
    return (
      <Typography variant="h5" align="center" py={4}>
        User not found.
      </Typography>
    );
  }

  const countryCode = getCountryCode(profileUser.country);

  return (
    <ProfileContainer isMobile={isMobile}>
      <Grid container spacing={isMobile ? 2 : 3} alignItems="center">
        {isMobile && (
          <Grid item xs={12} sx={{ textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
          <Avatar
            src={profileUser.photoUrl}
            alt={profileUser.name}
            sx={{
              width: isMobile ? 100 : 150,
              height: isMobile ? 100 : 150,
              margin: "0 auto",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} container spacing={isMobile ? 1 : 3}>
          <Grid item xs={6} textAlign="center">
            <StatBox>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {ridesCount}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Rides
              </Typography>
            </StatBox>
          </Grid>
          <Grid
            item
            xs={6}
            textAlign="center"
            onClick={() => navigate(`/myfriends/${uid}`)}
          >
            <StatBox>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {friendsCount}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Followers
              </Typography>
            </StatBox>
          </Grid>
          <Grid item xs={12} textAlign={isMobile ? "center" : "left"}>
            <Typography
              variant="h5"
              component="h1"
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              {profileUser.name}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ mb: 2 }}
            >
              @{profileUser.nickName}
            </Typography>
            {countryCode && (
              <Box display="flex" justifyContent="center" mb={2}>
                <Flag code={countryCode} height="20" />
              </Box>
            )}
            <Box textAlign="center" mb={2}>
              <Typography variant="body2" color="textSecondary">
                {profileUser.city}, {profileUser.state}, {profileUser.country}
              </Typography>
            </Box>
            {user?.uid === uid ? (
              <ActionButton
                variant="outlined"
                startIcon={<SettingsIcon />}
                onClick={handleSettingsClick}
                fullWidth={isMobile}
              >
                Edit Profile
              </ActionButton>
            ) : isFriend ? (
              <ActionButton
                variant="contained"
                onClick={handleUnfollow}
                fullWidth={isMobile}
                sx={{ backgroundColor: "#c14143", color: "white" }}
              >
                Eliminar
              </ActionButton>
            ) : friendRequestSent ? (
              <ActionButton
                variant="outlined"
                onClick={handleCancelFriendRequest}
                fullWidth={isMobile}
              >
                Cancel Request
              </ActionButton>
            ) : (
              <ActionButton
                variant="contained"
                onClick={() => {
                  if (user) {
                    handleAddFriend();
                  } else {
                    setShowLoginWarning(true);
                  }
                }}
                fullWidth={isMobile}
                sx={{ backgroundColor: "#427cd6", color: "white" }}
              >
                Follow
              </ActionButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={showLoginWarning}
        onClose={handleLoginWarningClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¡Alerta!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Debes estar registrado para interactuar con otros usuarios. ¿Deseas
            iniciar sesión ahora?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleLoginWarningClose();
              navigate("/login");
            }}
            color="primary"
          >
            Iniciar Sesión
          </Button>
          <Button
            onClick={() => {
              handleLoginWarningClose();
              navigate("/signup");
            }}
            color="primary"
          >
            Registrarse
          </Button>
        </DialogActions>
      </Dialog>
    </ProfileContainer>
  );
};

export default ProfileStats;
