import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import EventIcon from "@mui/icons-material/Event";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PeopleIcon from "@mui/icons-material/People";
import LandscapeIcon from "@mui/icons-material/Landscape";
import MapIcon from "@mui/icons-material/Map";
import Diversity3Icon from "@mui/icons-material/Diversity3";

const routes = [
  { label: "Eventos", path: "/event-list", icon: <EventIcon /> },
  { label: "Ranking", path: "/Leaderboards", icon: <LeaderboardIcon /> },
  { label: "Amigos", path: "/friends", icon: <PeopleIcon /> },
  { label: "Mapa en vivo", path: "/livemap", icon: <MapIcon /> },
  { label: "Parques", path: "/parks", icon: <LandscapeIcon /> },
  { label: "Equipos", path: "/teams", icon: <Diversity3Icon /> },
];

const SearchPanel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        maxWidth: "100%",
        margin: "auto",
        padding: isMobile ? 2 : 4,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Grid container spacing={isMobile ? 2 : 3} justifyContent="center">
        {routes.map((route, index) => (
          <Grid key={index} item xs={6} sm={4} md={3}>
            <Paper
              component={Link}
              to={route.path}
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: theme.spacing(2),
                height: "100%",
                textDecoration: "none",
                color: "inherit",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: theme.shadows[10],
                },
                borderRadius: theme.shape.borderRadius,
                backgroundColor: "black",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: "50%",
                  padding: theme.spacing(2),
                  marginBottom: theme.spacing(1),
                }}
              >
                {React.cloneElement(route.icon, {
                  fontSize: "large",
                  sx: { color: theme.palette.primary.contrastText },
                })}
              </Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  color: theme.palette.primary.contrastText,
                  textAlign: "center",
                }}
              >
                {route.label}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SearchPanel;
