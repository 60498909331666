import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";

export default function MobileNavbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const routes = [
    { path: "/", label: "Home", icon: HomeIcon },
    { path: "/carrito", label: "Carrito", icon: ShoppingCartIcon },
    { path: "/buscar", label: "Buscar", icon: SearchIcon },

    { path: "/profile", label: "Perfil", icon: AccountCircleIcon },
  ];

  const getCurrentValue = () => {
    return routes.findIndex((route) => route.path === location.pathname);
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: "white",
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "20px 20px 0 0",
        overflow: "hidden",
      }}
    >
      <BottomNavigation
        value={getCurrentValue()}
        onChange={(_, newValue) => navigate(routes[newValue].path)}
        sx={{
          height: 70,
          "& .MuiBottomNavigationAction-root": {
            color: "rgba(0, 0, 0, 0.5)",
            minWidth: "auto",
            padding: "6px 0",
            "&.Mui-selected": {
              color: "#232f3e",
            },
          },
        }}
      >
        {routes.map((route, index) => (
          <BottomNavigationAction
            key={index}
            label={route.label}
            icon={<route.icon sx={{ fontSize: 28 }} />}
            sx={{
              "&.Mui-selected": {
                "& .MuiBottomNavigationAction-label": {
                  fontSize: "0.75rem",
                  transition: "font-size 0.2s, opacity 0.2s",
                  opacity: 1,
                },
                "& .MuiSvgIcon-root": {
                  transition: "transform 0.2s",
                  transform: "translateY(-4px)",
                },
              },
              "& .MuiBottomNavigationAction-label": {
                fontSize: "0.70rem",
                transition: "font-size 0.2s, opacity 0.2s",
                opacity: 0.7,
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
}
