import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { Avatar, Box, Typography, Grid } from "@mui/material";

const EventParticipants = ({ eventId }) => {
  const [participants, setParticipants] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const eventDocRef = doc(db, "events", eventId);
        const eventDoc = await getDoc(eventDocRef);
        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          if (eventData.participants) {
            const participantPromises = eventData.participants.map(
              async (participantUid) => {
                const userDocRef = doc(db, "Users", participantUid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  return {
                    uid: participantUid,
                    name: userData.name,
                    nickName: userData.nickName,
                    photoUrl: userData.photoUrl,
                  };
                }
                return null;
              }
            );

            const participantsData = await Promise.all(participantPromises);
            setParticipants(
              participantsData.filter((participant) => participant !== null)
            );
          }
        }
      } catch (error) {
        console.error("Error al obtener los participantes:", error);
      }
    };

    fetchParticipants();
  }, [eventId]);

  const handleParticipantClick = (uid) => {
    navigate(`/user/${uid}`);
  };

  return (
    <Box mt={2}>
      <Typography variant="h5">Participantes:</Typography>
      <Grid container spacing={2} mt={1}>
        {participants.map((participant, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            onClick={() => handleParticipantClick(participant.uid)}
            sx={{ cursor: "pointer" }}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={participant.photoUrl}
                alt={`Avatar de ${participant.name}`}
                sx={{ mr: 2 }}
              />
              <Box>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {participant.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  @{participant.nickName}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EventParticipants;
