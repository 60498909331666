import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import {
  GoogleMap,
  LoadScript,
  OverlayView,
  KmlLayer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "700px",
};

const center = {
  lat: 29.11580571,
  lng: -110.93319296,
};

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const CustomMarker = ({ position, photoUrl, name, team, timestamp, speed }) => {
  // Convertir la velocidad de m/s a km/h
  const speedKmph = (speed || 0) * 3.6;

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div style={markerContainerStyle}>
        <div style={imageContainerStyle}>
          <img src={photoUrl} alt={name} style={imageStyle} />
        </div>
        <div style={textStyle}>
          <div>{name}</div>
          <div>Team: {team}</div>
          <div>Speed: {speedKmph.toFixed(2)} km/h</div>{" "}
          {/* Mostrar la velocidad en km/h */}
        </div>
      </div>
    </OverlayView>
  );
};

const markerContainerStyle = {
  textAlign: "center",
  width: "150px", // Ajustar el ancho según sea necesario
};

const imageContainerStyle = {
  background: "#66ff66",
  border: "1px solid #ccc",
  borderRadius: "50%",
  padding: "4px",
  width: "33px", // Ajustar el tamaño según sea necesario
  height: "33px", // Ajustar el tamaño según sea necesario
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "5px",
};

const imageStyle = {
  width: "25px", // Ajustar el tamaño según sea necesario
  height: "25px", // Ajustar el tamaño según sea necesario
  borderRadius: "50%",
};

const textStyle = {
  background: "#fff",
  color: "#333",
  fontSize: "10px",
  padding: "5px", // Aumentar el padding para dar más espacio al texto
  borderRadius: "5px",
};

const LiveMap = () => {
  const [riders, setRiders] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Users"), (snapshot) => {
      const updatedRiders = snapshot.docs
        .map((doc) => {
          const userData = doc.data();
          if (userData.location) {
            return {
              id: doc.id,
              position: {
                lat: userData.location.latitude,
                lng: userData.location.longitude,
              },
              name: userData.name,
              team: userData.team,
              photoUrl: userData.photoUrl,
              timestamp: userData.location.timestamp,
              speed: userData.location.speed,
            };
          }
          return null;
        })
        .filter((rider) => rider !== null);
      setRiders(updatedRiders);
    });

    return () => unsubscribe();
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
        {riders.map((rider) => (
          <CustomMarker
            key={rider.id}
            position={rider.position}
            photoUrl={rider.photoUrl}
            name={rider.name}
            team={rider.team}
            timestamp={rider.timestamp}
            speed={rider.speed}
          />
        ))}

        {/* KML Layer */}
        <KmlLayer
          url="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Enduro.kml?alt=media&token=cfb193ce-69b7-4739-8165-c5f492020891"
          options={{ preserveViewport: true }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default LiveMap;
