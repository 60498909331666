import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Avatar,
  CircularProgress,
} from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import axios from "axios";

const FloatingSupportButton = () => {
  const [openChat, setOpenChat] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);

  const API_KEY =
    "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";
  const ASSISTANT_ID = "asst_8DWMaL0BBRkqvdJNlCDuvFgR";

  useEffect(() => {
    createNewThread();
  }, []);

  const createNewThread = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
        "OpenAI-Beta": "assistants=v1",
      };

      const threadResponse = await axios.post(
        "https://api.openai.com/v1/threads",
        {},
        { headers }
      );

      if (threadResponse.data && threadResponse.data.id) {
        setThreadId(threadResponse.data.id);
      } else {
        throw new Error("Failed to create thread or retrieve thread ID");
      }
    } catch (error) {
      console.error("Error creating new thread:", error);
    }
  };

  const handleClick = () => {
    setOpenChat(true);
  };

  const handleSendMessage = async () => {
    if (message.trim() === "" || !threadId) {
      console.log("El mensaje está vacío o no hay ID de hilo, no se enviará.");
      return;
    }

    setIsLoading(true);

    const newMessage = {
      role: "user",
      content: message,
      name: "Usuario",
      avatar: "/path_to_user_avatar.jpg",
    };

    setChatHistory((prev) => [...prev, newMessage]);
    setMessage("");

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
        "OpenAI-Beta": "assistants=v1",
      };

      // Add user message to the thread
      await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          role: "user",
          content: message,
        },
        { headers }
      );

      // Create a new run in the thread
      const runResponse = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: ASSISTANT_ID,
          instructions:
            "Por favor, responde a la consulta del usuario de manera útil y concisa.",
        },
        { headers }
      );

      const runId = runResponse.data.id;

      // Polling to check when the run is completed
      let runStatus;
      do {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        runStatus = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
          { headers }
        );
      } while (["queued", "in_progress"].includes(runStatus.data.status));

      if (runStatus.data.status === "completed") {
        // Fetch the latest assistant message
        const messagesResponse = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/messages`,
          { headers }
        );

        const assistantMessage = messagesResponse.data.data.find(
          (msg) => msg.role === "assistant"
        );

        if (
          assistantMessage &&
          assistantMessage.content &&
          assistantMessage.content.length > 0
        ) {
          const botMessage = {
            role: "assistant",
            content: assistantMessage.content[0].text.value,
            name: "Soporte",
            avatar: "/path_to_bot_avatar.jpg",
          };
          setChatHistory((prev) => [...prev, botMessage]);
        }
      } else {
        console.error("Run failed or was cancelled:", runStatus.data.status);
        // Handle failed or cancelled run
      }
    } catch (error) {
      console.error("Error al comunicarse con OpenAI:", error);
      // You might want to add a user-friendly error message to the chat here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 100,
          right: 20,
          zIndex: 1000,
        }}
      >
        <Tooltip title="Soporte" arrow>
          <IconButton
            onClick={handleClick}
            sx={{
              backgroundColor: "#36475a",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#005f7a",
              },
              boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
              width: 56,
              height: 56,
              borderRadius: "50%",
            }}
          >
            <SupportAgentIcon sx={{ fontSize: 32 }} />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog open={openChat} onClose={() => setOpenChat(false)}>
        <DialogContent
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "12px",
            padding: "16px",
            minWidth: "400px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: "#000", textAlign: "center" }}
          >
            Chat de Soporte
          </Typography>
          <Box
            sx={{
              height: "300px",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "8px",
              marginBottom: "16px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
            }}
          >
            {chatHistory.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: msg.role === "user" ? "flex-end" : "flex-start",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.75rem", color: "#666" }}
                >
                  {msg.name}
                </Typography>
                <Box
                  sx={{
                    maxWidth: "80%",
                    padding: "8px 12px",
                    backgroundColor:
                      msg.role === "user" ? "#008CBA" : "#e0e0e0",
                    color: msg.role === "user" ? "#ffffff" : "#000000",
                    borderRadius: "12px",
                    wordWrap: "break-word",
                  }}
                >
                  <Avatar
                    src={msg.avatar}
                    sx={{
                      width: 24,
                      height: 24,
                      marginRight: "8px",
                      display: "inline-flex",
                      verticalAlign: "middle",
                    }}
                  />
                  {msg.content}
                </Box>
              </Box>
            ))}
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Escribe tu mensaje..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) =>
              e.key === "Enter" && !isLoading && handleSendMessage()
            }
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "8px",
            }}
            disabled={isLoading}
          />
          <Button
            onClick={handleSendMessage}
            variant="contained"
            sx={{
              marginTop: "16px",
              backgroundColor: "#36475a",
              color: "#ffffff",
              borderRadius: "24px",
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: "#005f7a",
              },
              display: "block",
              marginLeft: "auto",
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Enviar"
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloatingSupportButton;
