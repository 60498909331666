import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import axios from "axios";
import ProductDetail from "./ProductDetail";

const ProductDataFetcher = () => {
  const { documentName } = useParams();
  const [productData, setProductData] = useState(null);
  const [syscomData, setSyscomData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // Obtener los datos de Firebase
        const productRef = doc(db, "products", documentName);
        const productSnapshot = await getDoc(productRef);

        if (productSnapshot.exists()) {
          const firebaseProduct = productSnapshot.data();

          // Hacer la llamada a la API de Syscom usando el ID del producto de Firebase
          const productId = firebaseProduct["ID Producto"];
          const syscomResponse = await axios.get(
            `https://radiosistemas-a46cd.uc.r.appspot.com/producto/${productId}`
          );

          // Combinar ambos datos (Firebase y Syscom)
          setProductData(firebaseProduct);
          setSyscomData(syscomResponse.data);
        } else {
          setError("Producto no encontrado.");
        }
      } catch (err) {
        console.error("Error obteniendo el producto:", err);
        setError("Hubo un problema al obtener los datos.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [documentName]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return <ProductDetail product={productData} syscomData={syscomData} />;
};

export default ProductDataFetcher;
