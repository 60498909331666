import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Paper,
  useTheme,
  CircularProgress,
  Slide,
} from "@mui/material";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { useUserAuth } from "../../context/userAuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import UserForm from "./UserForm"; // Asegúrate de que la ruta es correcta
import Upgrades from "./Upgrades"; // Asegúrate de que la ruta es correcta

const NewUser = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useUserAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showUpgrades, setShowUpgrades] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const checkUserProfile = async () => {
      if (user && user.uid) {
        const userRef = doc(db, "Users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserInfo(userSnap.data());
        } else {
          setUserInfo(null);
        }
      }
      setLoading(false);
    };

    checkUserProfile();
  }, [user]);

  const handleCompleteProfile = () => {
    setShowForm(true);
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const handleFormComplete = () => {
    setShowForm(false);
    setShowUpgrades(true);
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  const isCompleteProfile = userInfo && userInfo.name && userInfo.nickName;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: "100vh",
        textAlign: "center",
        px: { xs: 2, sm: 4 },
        pt: { xs: 8, sm: 10 }, // Padding top para evitar superposición con el Navbar
        pb: 4, // Padding bottom para el contenido adicional
      }}
    >
      <Paper
        elevation={6}
        sx={{
          p: { xs: 3, sm: 4 },
          borderRadius: 3,
          backgroundColor: "white",
          color: "black",
          mb: 4,
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <Avatar
          sx={{
            width: 120,
            height: 120,
            mb: 2,
            bgcolor: theme.palette.primary.main,
            mx: "auto",
          }}
        >
          <DirectionsBikeIcon sx={{ fontSize: 80, color: "white" }} />
        </Avatar>
        {!isCompleteProfile ? (
          <>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              ¡Bienvenido a Livespeed.mx!
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Crea tu cuenta para continuar.
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Para poder continuar, completa tu perfil.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              ¡Bienvenido de nuevo, {userInfo?.name}!
            </Typography>
            <Avatar
              src={userInfo?.photoUrl}
              alt={userInfo?.name}
              sx={{
                width: 120,
                height: 120,
                mb: 2,
                mx: "auto",
              }}
            />
            <Typography variant="body1" sx={{ mb: 3 }}>
              Necesitas actualizar tu perfil.
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Para poder continuar, completa tu perfil.
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          onClick={handleCompleteProfile}
          sx={{
            borderRadius: 2,
            px: 3,
            py: 1.5,
            fontSize: "1.2rem",
            textTransform: "none",
            backgroundColor: "black",
            color: "white",
            "&:hover": {
              backgroundColor: "#333",
            },
          }}
        >
          Completar Perfil
        </Button>
      </Paper>

      <Slide direction="up" in={showForm} mountOnEnter unmountOnExit>
        <Box
          ref={formRef}
          sx={{ width: "100%", textAlign: "center", pb: 4, mt: 4 }}
        >
          <UserForm onComplete={handleFormComplete} />
        </Box>
      </Slide>

      <Slide direction="up" in={showUpgrades} mountOnEnter unmountOnExit>
        <Box
          ref={formRef}
          sx={{ width: "100%", textAlign: "center", pb: 4, mt: 4 }}
        >
          <Upgrades />
        </Box>
      </Slide>
    </Box>
  );
};

export default NewUser;
