import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  CircularProgress,
  Typography,
  Container,
  Box,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Para redirigir usando v6

const Categorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [subcategorias, setSubcategorias] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSub, setLoadingSub] = useState({});
  const [expandedCategoria, setExpandedCategoria] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Para manejar la navegación

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        setLoading(true);
        console.log("Fetching categories...");
        const response = await axios.get(
          "https://radiosistemas-a46cd.uc.r.appspot.com/categorias"
        );
        console.log("Categorías obtenidas:", response.data);
        setCategorias(response.data);
      } catch (err) {
        setError("Error al obtener las categorías.");
        console.error("Error obteniendo las categorías:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchCategorias();
  }, []);

  const fetchSubcategorias = async (categoriaId) => {
    if (subcategorias[categoriaId]) return; // Evitar fetch repetido si ya tenemos los datos

    try {
      console.log(`Fetching subcategorías for category ${categoriaId}...`);
      setLoadingSub((prev) => ({ ...prev, [categoriaId]: true }));

      const response = await axios.get(
        `https://radiosistemas-a46cd.uc.r.appspot.com/categorias/${categoriaId}`
      );

      console.log(`Respuesta obtenida para ${categoriaId}:`, response.data);

      if (response.data && response.data.subcategorias) {
        setSubcategorias((prev) => ({
          ...prev,
          [categoriaId]: response.data.subcategorias,
        }));
      } else {
        setSubcategorias((prev) => ({
          ...prev,
          [categoriaId]: [],
        }));
      }
    } catch (err) {
      console.error("Error obteniendo subcategorías:", err);
    } finally {
      setLoadingSub((prev) => ({ ...prev, [categoriaId]: false }));
    }
  };

  const handleToggle = (categoriaId) => {
    setExpandedCategoria((prev) => (prev === categoriaId ? null : categoriaId));
    fetchSubcategorias(categoriaId);
  };

  const handleSubcategoryClick = (subcatId) => {
    // Redirigir al usuario a /categoria/:categoria usando `useNavigate`
    navigate(`/productos/categoria/${subcatId}`);
  };

  return (
    <Container maxWidth="md" sx={styles.container}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        component={motion.div}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Categorías
      </Typography>

      {loading ? (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">
          {error}
        </Typography>
      ) : (
        <List component="nav" sx={styles.list}>
          <AnimatePresence>
            {categorias.map((categoria) => (
              <motion.div
                key={categoria.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <ListItem
                  button
                  onClick={() => handleToggle(categoria.id)}
                  sx={styles.listItem(isMobile)}
                >
                  <ListItemText
                    primary={categoria.nombre}
                    secondary={`Nivel ${categoria.nivel}`}
                    sx={styles.listItemText}
                  />
                  {expandedCategoria === categoria.id ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>

                <Collapse
                  in={expandedCategoria === categoria.id}
                  timeout="auto"
                  unmountOnExit
                >
                  {loadingSub[categoria.id] ? (
                    <Box sx={styles.subLoadingContainer}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Box sx={styles.subcategoryContainer(isMobile)}>
                      <AnimatePresence>
                        {subcategorias[categoria.id]?.length > 0 ? (
                          subcategorias[categoria.id].map((subcat, index) => (
                            <motion.div
                              key={subcat.id}
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              exit={{ opacity: 0, x: 20 }}
                              transition={{
                                duration: 0.2,
                                delay: index * 0.05,
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={styles.subcategoryButton}
                                onClick={() =>
                                  handleSubcategoryClick(subcat.id)
                                } // Redirige al hacer clic en la subcategoría
                              >
                                {subcat.nombre}
                              </Button>
                            </motion.div>
                          ))
                        ) : (
                          <Typography sx={styles.noSubcategories}>
                            No hay subcategorías
                          </Typography>
                        )}
                      </AnimatePresence>
                    </Box>
                  )}
                </Collapse>
              </motion.div>
            ))}
          </AnimatePresence>
        </List>
      )}
    </Container>
  );
};

const styles = {
  container: {
    paddingTop: "20px",
    paddingBottom: "20px",
    backgroundColor: "#ffffff", // Fondo blanco
    minHeight: "100vh",
  },
  list: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
    overflow: "hidden",
  },
  listItem: (isMobile) => ({
    backgroundColor: "#f0f0f0", // Color de fondo más suave
    color: "#333", // Color de texto más oscuro para mejor contraste
    padding: isMobile ? "10px 12px" : "14px 20px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  }),
  listItemText: {
    "& .MuiListItemText-primary": {
      fontSize: "18px",
      fontWeight: "500",
      color: "#333",
    },
    "& .MuiListItemText-secondary": {
      color: "#666",
    },
  },
  subcategoryContainer: (isMobile) => ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    flexWrap: "wrap",
    gap: "10px",
    padding: "15px 20px",
    backgroundColor: "#ffffff",
  }),
  subcategoryButton: {
    backgroundColor: "#232f3e", // Color azul más suave
    color: "#fff",
    fontSize: "14px",
    padding: "8px 16px",
    textTransform: "none",
    borderRadius: "20px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#1976d2",
      transform: "translateY(-2px)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "40px",
  },
  subLoadingContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  noSubcategories: {
    padding: "10px",
    fontSize: "14px",
    color: "#666",
    fontStyle: "italic",
  },
};

export default Categorias;
