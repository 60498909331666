// App.js
import React from "react";
import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { isMobile } from "react-device-detect";
import Welcome from "./components/Welcome";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import PhoneSignUp from "./components/PhoneSignUp";
import ProtectedRoute from "./components/ProtectedRoute";
import NotAuthorizedScreen from "./screens/NotAuthorizedScreen.jsx";
/////// Creacion de Eventos //////
import CreateEvent from "./components/Events/CreateEvent";
import EventList from "./components/Events/EventList";
import EventDetail from "./components/Events/EventDetail";
import PayEvent from "./components/Events/PayEvent.js";
/////// Leaderboards //////
import Leaderboards from "./components/Leaderboards/Leaderboards";
import UserMenu from "./components/Users/UserMenu";
import NavigationBar from "./Navbar";
import ProfileInfo from "./components/ProfileInfo"; // Importa tu componente ProfileInfo
import UserForm from "./components/Users/UserForm.js";
import MyTags from "./components/Users/MyTags"; // my tags
import BikeGarage from "./components/Users/BikeGarage";
import AddBike from "./components/Users/AddBike";
import Receiver from "./components/AdminPanel/Receivers/Receiver";
import FinishReceiver from "./components/AdminPanel/Receivers/FinishReceiver";
////// CamReceiver ////////
import CamReceiver from "./components/AdminPanel/Receivers/CamReceiver";
import NfcCam from "./components/AdminPanel/Receivers/NfcCam";
import MapCam from "./components/AdminPanel/Receivers/MapCam";
/////// LiveStats //////
import LiveStats from "./components/Leaderboards/LiveStats";
import ActiveRiders from "./components/AdminPanel/ActiveRiders";
import LiveMap from "./components/RiderLocation/LiveMap";
import LiveRider from "./components/Leaderboards/LiveRider";
import Teams from "./components/Teams/Teams";
import CreateTeam from "./components/Teams/CreateTeam";
import RiderLocation from "./components/RiderLocation/RiderLocation";
////// Admin //////
import ControlPanel from "./components/AdminPanel/ControlPanel";
import TagControl from "./components/AdminPanel/UsersControl/TagControl";
import UsersControl from "./components/AdminPanel/UsersControl/UsersControl";
import Enduro from "./components/Leaderboards/Enduro";
import Stats from "./components/Leaderboards/Stats";
import OfflineStartReceiver from "./components/AdminPanel/Receivers/OfflineStartReceiver";
import { UserAuthContextProvider } from "./context/userAuthContext";
import ManualStart from "./components/AdminPanel/Receivers/ManualStart";
import ManualLiveRider from "./components/Leaderboards/ManualLiveRider";
import CapitanLeaderboard from "./components/Leaderboards/CapitanLeaderboard";
import UploadRiders from "./utils/UploadRidersScreen";
////////////// WorkShops /////////////////
import CreateWorkShop from "./components/WorkShops/CreateWorkShop";
import WorkShop from "./components/WorkShops/WorkShop";
import AllWorkShops from "./components/WorkShops/AllWorkShops";
import StateCreator from "./components/AdminPanel/StateCreator";
import Prueba from "./components/WorkShops/Prueba";
import OnService from "./components/WorkShops/OnService";
import AdminWorkShop from "./components/WorkShops/AdminWorkShop";
import MyWorkShop from "./components/WorkShops/MyWorkShop";
import Service from "./components/WorkShops/Service";
//////////// Mrketplace //////////////
import Parks from "./components/AdminPanel/Parks/Parks";
import Marketplace from "./components/MarketPlace/MarketPlace";
import MarketDashboard from "./components/MarketPlace/MarketDashboard";
import Product from "./components/MarketPlace/Product/Product.js";
import CreateProduct from "./components/MarketPlace/CreateProduct.js";
// Ride //
import RideInfo from "./components/Rides/RideInfo";
import CreateRide from "./components/Rides/CreateRide";
import Profile from "./components/Profile/Profile";

import MyRides from "./components/Rides/MyRides.js";
/// Friends ///
import SearchFriends from "./components/Friends/SearchFriends";
import FriendRequests from "./components/Users/FriendRequests";
// Search ///
import SearchPanel from "./components/Search/SearchPanel";
// Parks
import AllParks from "./components/Parks/AllParks";
import ParkInfo from "./components/Parks/ParkInfo";
import CreatePark from "./components/Parks/CreatePark";
import EditPark from "./components/Parks/EditPark";
// Teams ///
import TeamManager from "./components/Teams/TeamManager";
import TeamPage from "./components/Teams/TeamPage";
import TeamAdmin from "./components/Teams/TeamAdmin";
import MyParks from "./components/Parks/MyParks";
import ParkDashboard from "./components/Parks/ParkDashboard";
import CreateTrack from "./components/Parks/CreateTrack";
// Active
import RunStats from "./components/Leaderboards/RunStats";
// CheckOut

import CheckOut from "./components/CheckOut/CheckOut.js";
import Success from "./components/CheckOut/Success";
import Failure from "./components/CheckOut/Failure";
import Pending from "./components/CheckOut/Pending";
import EventManagerDashboard from "./components/Events/EventManagerDashboard";
import MyEventsContainer from "./components/Events/MyEventsContainer.js";
import EditEvent from "./components/Events/EditEvent.jsx";

// Store

import CreateStore from "./components/Stores/CreateStore.js";
import AllStores from "./components/Stores/AllStores.js";

import MobileNavbar from "./MobileNavbar.js";
import EventHome from "./components/Events/EventHome.js";
import ReceiverManager from "./components/AdminPanel/Receivers/ReceiverManager.js";
import NewUser from "./components/Users/NewUser.js";

import CheckNewUser from "./CheckNewUser.js";
import ReceiverInfo from "./components/AdminPanel/Receivers/ReceiverInfo.js";
import MyTickets from "./components/Users/MyTickets.js";
import Wallet from "./components/SpeedCoins/Wallet.js";
import TransactionDetails from "./components/SpeedCoins/TransactionDetails.js";
import MyFriends from "./components/Users/MyFriends.js";
import Notifications from "./Notifications.js";
import SendNotification from "./components/SendNotification.js";
import AnalyticsTracker from "./AnalyticsTracker.js";

import FloatingSupportButton from "./components/Support/FloatingSupportButton.js";
import { Box } from "@mui/material";

// Productos

import ProductDataFetcher from "./components/store/ProductDataFetcher.js";
import Categorias from "./components/store/categorias/Categorias.js";
import ProductosCategoria from "./components/store/categorias/ProductosCategoria.js";
import { CurrencyProvider } from "./context/CurrencyContext.js";
import Search from "./components/store/productos/Search.js";

function App() {
  return (
    <UserAuthContextProvider>
      <CurrencyProvider>
        <NavigationBar />
        <Box
          sx={{ paddingTop: isMobile ? "10px" : "60px", paddingBottom: "20px" }}
        ></Box>

        <AnalyticsTracker />
        <Notifications />

        <Routes>
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/producto/:documentName"
            element={<ProductDataFetcher />}
          />
          <Route
            path="/productos/categoria/:id"
            element={<ProductosCategoria />}
          />
          <Route path="/search/:input" element={<Search />} />
          <Route path="/categorias" element={<Categorias />} />
          <Route path="/sendNotification" element={<SendNotification />} />
          <Route path="/MyFriends/:uid" element={<MyFriends />} />
          <Route path="/transaction/:uid" element={<TransactionDetails />} />
          <Route path="/wallet" element={<Wallet />} />

          <Route path="/receiverinfo/:uid" element={<ReceiverInfo />} />
          <Route path="/events" element={<EventHome />} />
          <Route path="/notauthorized" element={<NotAuthorizedScreen />} />
          <Route path="/myrides" element={<MyRides />} />
          <Route path="/createride/:uid" element={<CreateRide />} />
          <Route path="/createride" element={<CreateRide />} />
          <Route path="/run/:uid" element={<RunStats />} />
          <Route path="/createproduct" element={<CreateProduct />} />
          <Route path="/createtrack/:uid" element={<CreateTrack />} />
          <Route path="/myparks" element={<MyParks />} />
          <Route path="/parkpanel/:uid" element={<ParkDashboard />} />
          <Route path="/editpark/:uid" element={<EditPark />} />
          <Route path="/createpark" element={<CreatePark />} />
          <Route path="/teamadmin/:uid" element={<TeamAdmin />} />
          <Route path="/team/:uid" element={<TeamPage />} />
          <Route path="/myteams" element={<TeamManager />} />
          <Route path="/parks" element={<AllParks />} />
          <Route path="/search" element={<SearchPanel />} />
          <Route path="/friends" element={<SearchFriends />} />
          <Route path="/checkout/:uid" element={<CheckOut />} />
          <Route path="/ride/:rideId" element={<RideInfo />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/product/:uid" element={<Product />} />
          <Route path="/myproducts" element={<MarketDashboard />} />
          <Route
            path="/myworkshop"
            element={
              <ProtectedRoute role="workshopmanager">
                <AdminWorkShop />
              </ProtectedRoute>
            }
          />
          <Route path="/service" element={<OnService />} />
          <Route path="/service/:serviceId" element={<Service />} />
          <Route path="/statecreator" element={<StateCreator />} />
          <Route
            path="/geekdashboard"
            element={
              <ProtectedRoute role="admin">
                <ControlPanel />
              </ProtectedRoute>
            }
          />
          <Route path="/stores" element={<AllStores />} />
          <Route path="/createstore" element={<CreateStore />} />
          <Route path="/success/:orderId" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/pending" element={<Pending />} />
          <Route path="/payevent/:id" element={<PayEvent />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route path="/event-list" element={<EventList />} />
          <Route
            path="/my-events"
            element={
              <ProtectedRoute role="eventmanager">
                <MyEventsContainer />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<EventManagerDashboard />} />
            <Route path=":id" element={<EditEvent />} />
            <Route path="create-event" element={<CreateEvent />} />
          </Route>
          <Route path="/leaderboards" element={<Leaderboards />} />
          <Route path="/NuevoTaller" element={<CreateWorkShop />} />
          <Route path="/myworkshop/:workshopId" element={<MyWorkShop />} />
          <Route path="/profile/:nfcIdentifier" element={<ProfileInfo />} />
          <Route path="/workshop/:id" element={<WorkShop />} />
          <Route path="/workshops" element={<AllWorkShops />} />
          <Route
            path="/createworkshop/:workshopId"
            element={<CreateWorkShop />}
          />
          <Route path="/friendsrequests" element={<FriendRequests />} />
          <Route path="/ReceiverManager" element={<ReceiverManager />} />
          <Route path="/" element={<Welcome />} />
          <Route path="/user/:uid" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/phonesignup" element={<PhoneSignUp />} />
          <Route path="/addProfile" element={<UserForm />} />
          <Route path="/profile" element={<UserMenu />} />
          <Route path="/MyTags" element={<MyTags />} />
          <Route path="/MyBikes" element={<BikeGarage />} />
          <Route path="/parkss" element={<Parks />} />
          <Route path="/park/:uid" element={<ParkInfo />} />
          <Route path="/addBike" element={<AddBike />} />
          <Route path="/StartNFC" element={<Receiver />} />
          <Route path="/FinishNFC" element={<FinishReceiver />} />
          <Route path="/FinishCam" element={<CamReceiver />} />
          <Route path="/NFCcam" element={<NfcCam />} />
          <Route path="/MapCam" element={<MapCam />} />
          <Route path="/activeriders" element={<ActiveRiders />} />
          <Route path="/livemap" element={<LiveMap />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/createteam" element={<CreateTeam />} />
          <Route path="/userscontrol" element={<UsersControl />} />
          <Route path="/enduro" element={<Enduro />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/liverider" element={<LiveRider />} />
          <Route path="/LiveStats" element={<LiveStats />} />
          <Route path="/TagControl" element={<TagControl />} />
          <Route path="/OfflineStart" element={<OfflineStartReceiver />} />
          <Route path="/ManualStart" element={<ManualStart />} />
          <Route path="/ManualLiveRider" element={<ManualLiveRider />} />
          <Route path="/uploadTags" element={<UploadRiders />} />
          <Route path="/prueba" element={<Prueba />} />
          <Route path="/livelocation" element={<RiderLocation />} />
          <Route path="/capitanleaderboard" element={<CapitanLeaderboard />} />
          <Route path="/welcomeaboard" element={<NewUser />} />
        </Routes>
        <MobileNavbar />
        <FloatingSupportButton />
      </CurrencyProvider>
    </UserAuthContextProvider>
  );
}

export default App;
