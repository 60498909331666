import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Crea el contexto
export const CurrencyContext = createContext();

// Proveedor de contexto que envolverá la app
export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("MXN"); // Por defecto MXN
  const [tipoCambio, setTipoCambio] = useState(1); // Por defecto 1:1
  const [incrementoPrecio, setIncrementoPrecio] = useState(1.3); // Por defecto 30% de incremento

  // Función para cambiar entre MXN y USD
  const toggleCurrency = () => {
    setCurrency((prevCurrency) => (prevCurrency === "MXN" ? "USD" : "MXN"));
  };

  // Obtener el tipo de cambio desde la API
  const fetchTipoCambio = async () => {
    try {
      console.log("Fetching tipo de cambio...");
      const response = await axios.get(
        "https://radiosistemas-a46cd.uc.r.appspot.com/tipocambio/"
      );
      console.log("API Response:", response.data); // Verifica la respuesta completa de la API
      const tipoCambioValue = parseFloat(response.data.tipoCambio);

      // Asegurarse de que el tipo de cambio es válido antes de asignarlo
      if (!isNaN(tipoCambioValue)) {
        setTipoCambio(tipoCambioValue);
        console.log("Tipo de cambio actualizado:", tipoCambioValue);
      } else {
        console.error(
          "El tipo de cambio recibido no es un número válido:",
          response.data.tipoCambio
        );
      }
    } catch (error) {
      console.error("Error obteniendo el tipo de cambio:", error);
    }
  };

  useEffect(() => {
    fetchTipoCambio(); // Cargar el tipo de cambio al montar el componente
  }, []);

  // Función para actualizar el incremento del precio (por si quieres cambiarlo dinámicamente)
  const updateIncrementoPrecio = (nuevoIncremento) => {
    if (!isNaN(nuevoIncremento) && nuevoIncremento > 0) {
      setIncrementoPrecio(nuevoIncremento);
      console.log(
        `Incremento de precio actualizado al ${nuevoIncremento * 100}%`
      );
    } else {
      console.error("El incremento de precio debe ser un número positivo.");
    }
  };

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        tipoCambio,
        incrementoPrecio,
        toggleCurrency,
        updateIncrementoPrecio,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
