import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Avatar,
  Grid,
  Paper,
  styled,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  ShoppingCart,
  Lock,
  LocationOn,
  Business,
  Payment,
  CardGiftcard,
  Devices,
  Help,
} from "@mui/icons-material";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
  borderRadius: theme.spacing(2),
  transition: "box-shadow 0.4s ease-in-out, transform 0.2s ease-in-out",
  textDecoration: "none",
  "&:hover": {
    boxShadow: "0 6px 20px rgba(0,0,0,0.3)",
    transform: "translateY(-5px)",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: "50%",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const menuItems = [
  {
    label: "Mis pedidos",
    icon: ShoppingCart,
    description: "Rastrear paquetes, devolver pedidos o comprar algo de nuevo",
    path: "/mis-pedidos",
  },
  {
    label: "Inicio de sesión y seguridad",
    icon: Lock,
    description:
      "Cambiar correo electrónico, contraseña y número de teléfono móvil",
    path: "/security",
  },
  {
    label: "Radeo Express",
    icon: Business,
    description:
      "Administrar tu membresía, consultar los beneficios y la configuración de pago",
    path: "/prime",
  },
  {
    label: "Direcciones",
    icon: LocationOn,
    description: "Editar direcciones para pedidos y regalos",
    path: "/direcciones",
  },
  {
    label: "Mis pagos",
    icon: Payment,
    description:
      "Administrar o agregar métodos de pago y ver tus transacciones",
    path: "/mis-pagos",
  },
  {
    label: "Tarjetas de regalo",
    icon: CardGiftcard,
    description: "Ver el saldo o canjear una tarjeta",
    path: "/tarjetas-de-regalo",
  },
  {
    label: "Soporte para dispositivos",
    icon: Devices,
    description:
      "Soluciona problemas de dispositivos, administra o cancela suscripciones digitales",
    path: "/soporte-dispositivos",
  },
  {
    label: "Servicio al cliente",
    icon: Help,
    description:
      "Explorar las opciones de autoservicio, artículos de ayuda o contáctanos",
    path: "/servicio-cliente",
  },
];

const UserMenu = () => {
  const [userInfo, setUserInfo] = useState({});
  const theme = useTheme();
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (user) {
        const docRef = doc(firestore, "Users", user.uid);
        const userSnap = await getDoc(docRef);

        if (userSnap.exists()) {
          setUserInfo(userSnap.data());
        }
      }
    };

    fetchUserData();
  }, [auth, firestore]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
        <Avatar
          src={userInfo?.photoUrl || "https://via.placeholder.com/80"}
          alt={userInfo?.name || "User Name"}
          sx={{
            width: 80,
            height: 80,
            mr: 2,
            border: `3px solid ${theme.palette.primary.main}`,
          }}
        />
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 0.5 }}>
            Mi cuenta
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {userInfo?.name || "User Name"}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={4}>
        {menuItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledPaper component={Link} to={item.path}>
              <IconWrapper>
                <item.icon sx={{ color: "#232f3e" }} fontSize="large" />
              </IconWrapper>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {item.label}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default UserMenu;
