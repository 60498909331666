import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Box,
  Tooltip,
  Button,
  Badge,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Logout from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useUserAuth } from "./context/userAuthContext";
import { CurrencyContext } from "./context/CurrencyContext";
import { db } from "./firebase";
import { doc, onSnapshot } from "firebase/firestore";
import AlgoliaSearch from "./AlgoliaSearch"; // Import the new AlgoliaSearch component

const NavbarComponent = () => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const { currency, tipoCambio, toggleCurrency } = useContext(CurrencyContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [photoURL, setPhotoURL] = useState("");
  const [userType, setUserType] = useState("");
  const [cartItemCount, setCartItemCount] = useState(0);

  useEffect(() => {
    if (user && user.uid) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setPhotoURL(userData.photoUrl || "");
          setUserType(userData.type || "");
          setCartItemCount(userData.cartItems?.length || 0);
        } else {
          setPhotoURL("");
          setUserType("");
          setCartItemCount(0);
        }
      });
      return () => unsubscribe();
    } else {
      setPhotoURL("");
      setUserType("");
      setCartItemCount(0);
    }
  }, [user]);

  const navItems = [
    { label: "Home", path: "/home", icon: <DashboardIcon /> },
    { label: "Perfil", path: "/Profile", icon: <AccountCircleIcon /> },
    { label: "Categorias", path: "/categorias", icon: <AccountCircleIcon /> },
  ];

  const restrictedNavItems = [
    { label: "Iniciar Sesión", path: "/login", icon: <AccountCircleIcon /> },
    { label: "Registro", path: "/signup", icon: <AccountCircleIcon /> },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/home");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <AppBar position="fixed" elevation={0} sx={{ bgcolor: "#232f3e" }}>
      <Toolbar sx={{ justifyContent: "space-between", px: { xs: 2, md: 10 } }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/" className="navbar-brand">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/radeo%2Fradeowhitelogo_797098c4-4b83-40c9-b29a-df23598ebe43%20(1).webp?alt=media&token=e5808804-10d5-4f15-a1c7-6f4c36c0d5b6"
              alt="Logo"
              style={{ height: "30px", marginRight: "20px" }}
            />
          </Link>
          {/* Integrating the AlgoliaSearch component */}
          <AlgoliaSearch />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {userType === "admin" && (
            <Button
              variant="contained"
              color="error"
              startIcon={<AdminPanelSettingsIcon />}
              sx={{ mr: 2, px: 1, py: 0.5, minWidth: "auto", fontSize: 12 }}
              component={Link}
              to="/geekdashboard"
            >
              Admin
            </Button>
          )}

          <Tooltip title="Carrito de compras">
            <IconButton color="inherit" component={Link} to="/cart">
              <Badge badgeContent={cartItemCount} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title="Ajustes de cuenta">
            <IconButton
              onClick={handleMenuClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <AccountCircleIcon sx={{ fontSize: 28, color: "#fff" }} />
            </IconButton>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {(user ? navItems : restrictedNavItems).map((item) => (
              <MenuItem key={item.path} component={Link} to={item.path}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.label}
              </MenuItem>
            ))}

            {user && (
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Cerrar Sesión
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Toolbar>

      {/* Barra inferior con opciones y tipo de cambio */}
      <Toolbar
        sx={{
          minHeight: "30px",
          justifyContent: "center",
          bgcolor: "#37475A",
        }}
      >
        <Button
          color="inherit"
          sx={{ fontSize: "0.65rem", mx: 1 }}
          onClick={() => navigate("/categorias")}
        >
          Categorías
        </Button>
        <Button
          color="inherit"
          sx={{ fontSize: "0.65rem", mx: 1 }}
          onClick={() => navigate("/vender")}
        >
          Vender
        </Button>

        {/* Dropdown para tipo de cambio */}
        <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
          <Typography sx={{ color: "white", fontSize: "0.75rem", mr: 1 }}>
            Dolar: ${isNaN(tipoCambio) ? "Cargando..." : tipoCambio}
          </Typography>
          <Button
            onClick={toggleCurrency}
            sx={{ color: "white", fontSize: "0.75rem" }}
          >
            {currency === "MXN" ? "MXN" : "USD"}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarComponent;
