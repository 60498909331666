import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  LinearProgress,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import RouterIcon from "@mui/icons-material/Router";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import { db } from "../../../firebase"; // Asegúrate de que la ruta es correcta
import {
  collection,
  doc,
  onSnapshot,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const convertVoltage = (vsysVoltage) => {
  // Realiza la conversión del voltaje
  return (vsysVoltage * 4.13) / 0.5820207;
};

const calculateBatteryPercentage = (voltage) => {
  // Supongamos que 4.2V es 100% y 3.0V es 0%
  const minVoltage = 3.0;
  const maxVoltage = 4.2;

  if (voltage >= maxVoltage) {
    return 100;
  } else if (voltage <= minVoltage) {
    return 0;
  } else {
    return ((voltage - minVoltage) / (maxVoltage - minVoltage)) * 100;
  }
};

const fixedCoordinates = "40.7128, -74.0060";

const ReceiverManager = () => {
  const [receivers, setReceivers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReceivers = () => {
      const receiversCollection = collection(db, "Receivers");
      const q = query(receiversCollection);

      const unsubscribe = onSnapshot(q, async (receiverSnapshot) => {
        const receiverDataPromises = receiverSnapshot.docs.map(
          async (receiverDoc) => {
            const receiver = receiverDoc.data();
            const pingsCollection = collection(
              db,
              `Receivers/${receiverDoc.id}/Pings`
            );
            const latestPingQuery = query(
              pingsCollection,
              orderBy("time", "desc"),
              limit(1)
            );

            const latestPingSnapshot = await getDocs(latestPingQuery);

            if (!latestPingSnapshot.empty) {
              const latestPing = latestPingSnapshot.docs[0].data();
              latestPing.vsysVoltage = convertVoltage(latestPing.vsysVoltage);
              latestPing.batteryPercentage = calculateBatteryPercentage(
                latestPing.vsysVoltage
              );
              receiver.latestPing = latestPing;

              receiver.isConnected = true;
              receiver.secondsSinceLastPing = 0;
            } else {
              receiver.isConnected = false;
              receiver.secondsSinceLastPing = null;
            }

            receiver.id = receiverDoc.id;

            return receiver;
          }
        );

        const receiverData = await Promise.all(receiverDataPromises);
        setReceivers(receiverData);
      });

      return unsubscribe;
    };

    fetchReceivers();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setReceivers((prevReceivers) =>
        prevReceivers.map((receiver) => {
          if (receiver.latestPing) {
            const now = moment();
            const pingTime = moment(receiver.latestPing.time);
            const diffSeconds = now.diff(pingTime, "seconds");

            return {
              ...receiver,
              isConnected: diffSeconds <= 20,
              secondsSinceLastPing: diffSeconds,
            };
          }
          return receiver;
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleCardClick = (id) => {
    navigate(`/receiverinfo/${id}`);
  };

  const filteredReceivers = receivers.filter((receiver) => {
    return (
      receiver.stage.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filter === "all" ||
        (filter === "connected" && receiver.isConnected) ||
        (filter === "disconnected" && !receiver.isConnected))
    );
  });

  return (
    <Box
      p={4}
      sx={{ backgroundColor: "#ffffff", minHeight: "100vh", color: "#000" }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "#000",
          fontWeight: "bold",
          mb: 6,
          textTransform: "uppercase",
          letterSpacing: "0.1em",
        }}
      >
        Receiver Manager
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <TextField
          label="Buscar Receivers"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            width: "60%",
            "& .MuiOutlinedInput-root": {
              color: "#000",
              "& fieldset": {
                borderColor: "#000",
              },
              "&:hover fieldset": {
                borderColor: "#000",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#000",
            },
          }}
        />
        <FormControl sx={{ width: "35%" }}>
          <InputLabel sx={{ color: "#000" }}>Filtrar por Estado</InputLabel>
          <Select
            value={filter}
            onChange={handleFilterChange}
            label="Filtrar por Estado"
            sx={{
              color: "#000",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
              },
            }}
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="connected">Conectados</MenuItem>
            <MenuItem value="disconnected">Desconectados</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={4}>
        {filteredReceivers.map((receiver) => (
          <Grid key={receiver.id} item xs={12} sm={6} md={4}>
            <Card
              onClick={() => handleCardClick(receiver.id)}
              sx={{
                backgroundColor: "#f5f5f5",
                color: "#000",
                boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(4px)",
                borderRadius: "16px",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                height: "100%",
                position: "relative",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
                },
                cursor: "pointer",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 3,
                  }}
                >
                  <RouterIcon fontSize="large" sx={{ color: "#000" }} />
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", letterSpacing: "0.05em" }}
                  >
                    {receiver.stage}
                  </Typography>
                  <FiberManualRecordIcon
                    sx={{
                      color: receiver.isConnected ? "#4caf50" : "#f44336",
                      position: "absolute",
                      top: 22,
                      left: 202,
                    }}
                  />
                </Box>
                <Typography variant="body1" sx={{ mb: 2, opacity: 0.8 }}>
                  Posición: {receiver.stage}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <BatteryChargingFullIcon sx={{ mr: 1, color: "#4caf50" }} />
                  <Typography variant="body2">
                    Batería: {Math.round(receiver.latestPing.batteryPercentage)}
                    %
                  </Typography>
                </Box>
                <Box sx={{ width: "100%", mb: 3 }}>
                  <LinearProgress
                    variant="determinate"
                    value={receiver.latestPing.batteryPercentage}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: "#e0e0e0",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                          receiver.latestPing.batteryPercentage > 50
                            ? "#4caf50"
                            : receiver.latestPing.batteryPercentage > 20
                            ? "#ff9800"
                            : "#f44336",
                      },
                    }}
                  />
                </Box>
                {receiver.solarPanel && (
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <SolarPowerIcon sx={{ mr: 1, color: "#ffeb3b" }} />
                    <Typography variant="body2">
                      Voltaje Solar:{" "}
                      {receiver.latestPing.vsysVoltage.toFixed(1)}V
                    </Typography>
                  </Box>
                )}
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <SignalWifi4BarIcon sx={{ mr: 1, color: "#000" }} />
                  <Typography variant="body2">
                    Conexión:{" "}
                    {receiver.isConnected ? "Conectado" : "Desconectado"}
                  </Typography>
                  <Tooltip
                    title={receiver.isConnected ? "Conectado" : "Desconectado"}
                  >
                    <IconButton size="small">
                      <CircularProgress
                        size={24}
                        thickness={5}
                        variant="determinate"
                        value={receiver.isConnected ? 100 : 0}
                        sx={{
                          color: receiver.isConnected ? "#4caf50" : "#f44336",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <LocationOnIcon sx={{ mr: 1, color: "#000" }} />
                  <Typography variant="body2">
                    Coordenadas: {fixedCoordinates}
                  </Typography>
                </Box>
                {receiver.latestPing && (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <ThermostatIcon
                        sx={{
                          mr: 1,
                          color:
                            receiver.latestPing.temperature < 30
                              ? "#4caf50"
                              : receiver.latestPing.temperature < 50
                              ? "#ff9800"
                              : "#f44336",
                        }}
                      />
                      <Typography variant="body2">
                        Temperatura: {receiver.latestPing.temperature} °C
                      </Typography>
                    </Box>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      Último ping:{" "}
                      {moment(receiver.latestPing.time).format("MM-DD HH:mm")}
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      Voltaje: {receiver.latestPing.vsysVoltage.toFixed(2)} V
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                      Segundos desde el último ping:{" "}
                      {receiver.secondsSinceLastPing}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReceiverManager;
