import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Typography,
  Container,
  Box,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Button,
  Stack,
  Grid,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import TerrainIcon from "@mui/icons-material/Terrain";
import PaidIcon from "@mui/icons-material/Paid";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";

const EventHome = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsData = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
      }));

      // Ordenar eventos por fecha descendente (de más reciente a más antiguo)
      const sortedEvents = eventsData.sort((a, b) => b.dateTime - a.dateTime);
      setEvents(sortedEvents);
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const isEventPast = (eventDate) => {
    const today = new Date();
    return eventDate < today;
  };

  return (
    <Container sx={{ mt: 3, mb: 6 }}>
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        sx={{ fontWeight: "bold", color: "black" }}
      >
        Próximos Eventos
      </Typography>
      <Grid container spacing={3}>
        {events.map((event) => {
          const isPast = isEventPast(event.dateTime);
          return (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 2,
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  height: "100%",
                  transition: "transform 0.3s",
                  position: "relative",
                  opacity: isPast ? 0.5 : 1,
                  "&:hover": {
                    transform: !isPast ? "translateY(-5px)" : "none",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={event.photo}
                  alt={`Foto del evento ${event.id}`}
                  sx={{
                    height: 500,
                    objectFit: "cover",
                    cursor: "pointer",
                    filter: isPast ? "grayscale(100%)" : "none",
                  }}
                  onClick={!isPast ? () => handleEventClick(event.id) : null}
                />
                {isPast && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Evento Finalizado
                    </Typography>
                  </Box>
                )}
                <CardContent sx={{ p: 2, flex: "1 0 auto" }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {event.eventName}
                    </Typography>
                    <Chip
                      icon={
                        event.price && event.price > 0 ? (
                          <PaidIcon />
                        ) : (
                          <FreeBreakfastIcon />
                        )
                      }
                      label={
                        event.price && event.price > 0
                          ? `$${event.price}`
                          : "Gratuito"
                      }
                      size="small"
                      color={
                        event.price && event.price > 0 ? "secondary" : "primary"
                      }
                    />
                  </Stack>
                  <Stack direction="row" alignItems="center" mb={1}>
                    <EventIcon fontSize="small" sx={{ mr: 1, color: "gray" }} />
                    <Typography variant="body2" color="textSecondary">
                      {event.dateTime?.toLocaleDateString()}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" mb={1}>
                    <PersonIcon
                      fontSize="small"
                      sx={{ mr: 1, color: "gray" }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {event.creatorName}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" mb={1}>
                    <TerrainIcon
                      fontSize="small"
                      sx={{ mr: 1, color: "gray" }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      Nivel: {event.skillLevel}
                    </Typography>
                  </Stack>
                  <Box mt={2} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => handleEventClick(event.id)}
                      disabled={isPast}
                      sx={{
                        borderRadius: 25,
                        textTransform: "none",
                        backgroundColor: isPast ? "gray" : "#1976d2",
                        color: "white",
                        "&:hover": {
                          backgroundColor: isPast ? "gray" : "#1565c0",
                        },
                      }}
                    >
                      {isPast ? "Evento Pasado" : "Registrate"}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default EventHome;
