import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import {
  Typography,
  Box,
  Avatar,
  Container,
  Paper,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { Brightness1 } from "@mui/icons-material";

const ActiveRiders = () => {
  const [activeRuns, setActiveRuns] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLedOn, setIsLedOn] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      setIsLedOn((prevIsLedOn) => !prevIsLedOn);
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "Runs"), where("ActiveRun", "==", true)),
      (snapshot) => {
        const activeRunsData = [];
        snapshot.forEach(async (doc) => {
          const runData = doc.data();
          const tagData = await getTagData(runData.NFCIdentifier);
          if (tagData) {
            runData.Owner = await getUserName(tagData.Owner);
            runData.ParkName = await getParkName(runData.ParkID);
            runData.TrackName = await getTrackName(
              runData.ParkID,
              runData.TrackID,
            );
            runData.photoUrl = await getUserPhotoUrl(tagData.Owner);
            activeRunsData.push(runData);
          }
        });
        setActiveRuns(activeRunsData);
      },
      (error) => console.error("Error fetching active runs:", error),
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const getTagData = async (NFCIdentifier) => {
    try {
      const tagsCollectionRef = collection(db, "Tags");
      const q = query(tagsCollectionRef, where("NFCID", "==", NFCIdentifier));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const tagDoc = querySnapshot.docs[0];
        return tagDoc.data();
      } else {
        console.log("No tag found for NFCIdentifier:", NFCIdentifier);
        return null;
      }
    } catch (error) {
      console.error("Error getting tag data:", error);
      return null;
    }
  };

  const getUserName = async (userID) => {
    try {
      const userDoc = await getDoc(doc(db, "Users", userID));
      if (userDoc.exists()) {
        return userDoc.data().name;
      } else {
        return "Unknown";
      }
    } catch (error) {
      console.error("Error getting user data:", error);
      return "Unknown";
    }
  };

  const getParkName = async (parkID) => {
    try {
      const parkDoc = await getDoc(doc(db, "Parks", parkID));
      if (parkDoc.exists()) {
        return parkDoc.data().ParkName;
      } else {
        return "Unknown Park";
      }
    } catch (error) {
      console.error("Error getting park data:", error);
      return "Unknown Park";
    }
  };

  const getTrackName = async (parkID, trackID) => {
    try {
      const trackDoc = await getDoc(
        doc(db, "Parks", parkID, "Tracks", trackID),
      );
      if (trackDoc.exists()) {
        return trackDoc.data().TrackName;
      } else {
        return "Unknown Track";
      }
    } catch (error) {
      console.error("Error getting track data:", error);
      return "Unknown Track";
    }
  };

  const getUserPhotoUrl = async (userID) => {
    try {
      const userDoc = await getDoc(doc(db, "Users", userID));
      if (userDoc.exists()) {
        return userDoc.data().photoUrl;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting user photo URL:", error);
      return null;
    }
  };

  const calculateElapsedTime = (startTime) => {
    const startTimestamp = new Date(startTime).getTime(); // Convertir el inicio de tiempo a milisegundos
    const currentTimestamp = currentTime.getTime();
    const elapsedMilliseconds = currentTimestamp - startTimestamp;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const hours = Math.floor(elapsedSeconds / 3600);
    const minutes = Math.floor((elapsedSeconds % 3600) / 60);
    const seconds = elapsedSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Active Riders
      </Typography>
      <Grid container spacing={3}>
        {activeRuns.length === 0 ? (
          <Typography variant="subtitle1">No active runs found.</Typography>
        ) : (
          activeRuns.map((run, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Avatar
                      sx={{ width: 100, height: 100, mb: 2 }}
                      alt={run.Owner}
                      src={run.photoUrl}
                    />
                    <Typography variant="subtitle1" fontWeight="bold">
                      Identificador:
                    </Typography>
                    <Typography variant="body1">{run.NFCIdentifier}</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Parque:
                    </Typography>
                    <Typography variant="body1">{run.ParkName}</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Pista:
                    </Typography>
                    <Typography variant="body1">{run.TrackName}</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Rider:
                    </Typography>
                    <Typography variant="body1">
                      {run.Owner ? run.Owner : "Unknown"}
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Salida:
                    </Typography>
                    <Typography variant="body1">
                      {run.StartTime.toLocaleString()}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      color="primary"
                    >
                      Elapsed Time: {calculateElapsedTime(run.StartTime)}
                      <Brightness1
                        style={{
                          verticalAlign: "middle",
                          animation: isLedOn ? "blink 1.5s infinite" : "none",
                          opacity: isLedOn ? 0.5 : 0.2,
                          color: "green",
                        }}
                      />
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default ActiveRiders;
