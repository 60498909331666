import React from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const products = [
  {
    id: 1,

    description:
      "El último smartphone con tecnología de punta y diseño elegante.",

    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/hiksemi-promo-ago-sep-1080.jpg",
  },
  {
    id: 2,

    description: "Experiencia de sonido envolvente con cancelación de ruido.",

    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/3000x1080-switches.jpg",
  },
  {
    id: 2,

    description: "Experiencia de sonido envolvente con cancelación de ruido.",

    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/3000x1080-mejoresprecios.jpg",
  },
  {
    id: 2,

    description: "Experiencia de sonido envolvente con cancelación de ruido.",

    image:
      "https://ftp3.syscom.mx/usuarios/ftp/banners_index/syscom/garage-madera-3000x1080.jpg",
  },
];

const Header = () => {
  return (
    <Box
      component="header"
      sx={{
        width: "100%",
        padding: "10px 0",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff", // Fondo blanco para toda la sección
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: { xs: "95%", md: "98%" }, // Ocupa casi toda la pantalla
          margin: "0 auto",
          borderRadius: "15px", // Bordes redondeados para el contenedor
          overflow: "hidden", // Asegura que los bordes redondeados se apliquen correctamente
          position: "relative",
          backgroundColor: "#fff", // Fondo blanco para el slider
        }}
      >
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={10}
          slidesPerView={1}
          autoplay={{ delay: 2500 }} // Intervalo de cambio reducido
          pagination={{ clickable: true }}
          navigation
          loop
          style={{ marginBottom: "20px" }} // Espacio reducido entre el slider y otros elementos
        >
          {products.map((product) => (
            <SwiperSlide key={product.id}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: { xs: "180px", sm: "250px", md: "300px" }, // Altura más pequeña
                  backgroundColor: "#fff",
                  borderRadius: "15px", // Bordes redondeados
                  overflow: "hidden",
                  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.08)", // Sombra suave
                  position: "relative",
                }}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Asegura que la imagen siempre salga completa
                    borderRadius: "15px", // Bordes redondeados para la imagen
                  }}
                />
                {/* Información del producto sobre la imagen */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "15px",
                    left: "15px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "#fff",
                    padding: "5px 15px",
                    borderRadius: "8px",
                  }}
                ></Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Header;
