import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Avatar,
  Box,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import WorkShopServices from "./WorkShopServices";
import Stats from "./AdminWorkShop/Stats";

const MyWorkShop = () => {
  const [workshop, setWorkshop] = useState(null);
  const { workshopId } = useParams();
  const firestore = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkshop = async () => {
      const docRef = doc(firestore, "workshops", workshopId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setWorkshop({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
    };

    fetchWorkshop();
  }, [workshopId, firestore]);

  const handleEdit = () => {
    navigate(`/createworkshop/${workshopId}`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card elevation={3}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={2}>
              {workshop && workshop.workshopLogo && (
                <Avatar
                  alt={workshop.workshopName}
                  src={workshop.workshopLogo}
                  sx={{ width: 80, height: 80 }}
                />
              )}
              <Typography variant="h4" ml={2}>
                {workshop?.workshopName}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleEdit}
              fullWidth
            >
              Editar Taller
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper elevation={3} sx={{ padding: 2, height: "100%" }}>
          <Stats />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ padding: 2, marginTop: 3 }}>
          <WorkShopServices />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MyWorkShop;
