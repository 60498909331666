import React, { useState, useRef } from "react";
import Slider from "react-slick";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ZoomInIcon from "@mui/icons-material/ZoomIn"; // Icono de zoom
import CloseIcon from "@mui/icons-material/Close"; // Icono de cerrar
import IconButton from "@mui/material/IconButton";

const ImageSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [zoomOpen, setZoomOpen] = useState(false);
  const [zoomImage, setZoomImage] = useState("");
  const sliderRef = useRef(null); // Ref para controlar el slider

  // Configuración del slider
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),
    responsive: [
      {
        breakpoint: 768, // Configuración para móvil
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, // Sin flechas en móvil
        },
      },
    ],
  };

  // Función para abrir la imagen en zoom
  const handleZoomClick = (img) => {
    setZoomImage(img);
    setZoomOpen(true);
  };

  // Función para cambiar el slide al hacer clic en una miniatura
  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
    sliderRef.current.slickGoTo(index); // Cambia el slide en el slider
  };

  return (
    <div style={styles.sliderContainer}>
      <Slider ref={sliderRef} {...sliderSettings}>
        {images.map((img, index) => (
          <div key={index} style={styles.sliderImageContainer}>
            <img
              src={img.imagen}
              alt={`Imagen ${index}`}
              style={styles.image}
            />
            {/* Botón de zoom */}
            <IconButton
              onClick={() => handleZoomClick(img.imagen)}
              style={styles.zoomIconContainer}
            >
              <ZoomInIcon style={styles.zoomIcon} />
            </IconButton>
          </div>
        ))}
      </Slider>

      <div style={styles.thumbnailContainer}>
        {images.map((img, index) => (
          <img
            key={index}
            src={img.imagen}
            alt={`Thumbnail ${index}`}
            style={{
              ...styles.thumbnail,
              border:
                currentSlide === index
                  ? "2px solid #007bff"
                  : "2px solid transparent",
            }}
            onClick={() => handleThumbnailClick(index)} // Cambiar el slide al hacer clic en la miniatura
          />
        ))}
      </div>

      {/* Modal para la vista en zoom de la imagen */}
      <Modal open={zoomOpen} onClose={() => setZoomOpen(false)}>
        <Box style={styles.modalZoomContainer}>
          {/* Imagen ampliada */}
          <img src={zoomImage} alt="Imagen ampliada" style={styles.zoomImage} />
          {/* Botón de cierre del modal */}
          <IconButton
            onClick={() => setZoomOpen(false)}
            style={styles.closeIconContainer}
          >
            <CloseIcon style={styles.closeIcon} />
          </IconButton>
        </Box>
      </Modal>
    </div>
  );
};

// Estilos
const styles = {
  sliderContainer: {
    width: "100%",
    maxWidth: "800px",
    margin: "0 auto",
  },
  sliderImageContainer: {
    position: "relative",
    textAlign: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "500px",
    objectFit: "contain",
    borderRadius: "10px",
  },
  zoomIconContainer: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: "50%",
    padding: "5px",
    cursor: "pointer",
  },
  zoomIcon: {
    color: "#fff",
    fontSize: "30px",
  },
  thumbnailContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap", // Ajuste para pantallas pequeñas
    marginTop: "10px",
    gap: "10px",
  },
  thumbnail: {
    width: "60px", // Tamaño adecuado para móvil
    height: "60px",
    objectFit: "contain",
    cursor: "pointer",
    transition: "border 0.3s",
    borderRadius: "8px",
  },
  modalZoomContainer: {
    position: "relative", // Para colocar el botón de cerrar
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  zoomImage: {
    maxWidth: "90%",
    maxHeight: "90%",
    borderRadius: "10px",
  },
  closeIconContainer: {
    position: "absolute",
    top: "20px",
    right: "20px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    padding: "8px",
  },
  closeIcon: {
    color: "#000",
    fontSize: "30px",
  },
};

export default ImageSlider;
