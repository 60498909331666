import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  CircularProgress,
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Pagination,
  Rating,
  IconButton,
  Link,
} from "@mui/material";
import { LocalShipping, ShoppingCart, Favorite } from "@mui/icons-material";

const Search = () => {
  const { input } = useParams(); // Extraemos el parámetro de búsqueda desde la URL
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);

  const handleChangePagina = (event, value) => {
    setPagina(value);
  };

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://radiosistemas-a46cd.uc.r.appspot.com/productos/search?query=${input}&pagina=${pagina}&cantidad=20`
        );
        console.log("Respuesta completa de la API:", response.data);
        if (response.data.productos) {
          setProductos(response.data.productos);
          setTotalPaginas(response.data.paginas);
          console.log("Productos obtenidos:", response.data.productos);
        } else {
          setError("No se encontraron productos.");
        }
      } catch (err) {
        setError("Error al obtener productos.");
        console.error("Error obteniendo productos:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProductos();
  }, [input, pagina]);

  // Función para generar un número de reviews y rating aleatorio
  const generateRandomReviews = () => {
    const rating = (Math.random() * 2 + 3).toFixed(1); // Rating entre 3.0 y 5.0
    const reviews = Math.floor(Math.random() * 1000) + 1; // Número de reviews entre 1 y 1000
    return { rating, reviews };
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(price);
  };

  return (
    <Container maxWidth="lg" sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Resultados de la búsqueda: {input}
      </Typography>

      {loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "40px" }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">
          {error}
        </Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            {productos.length > 0 ? (
              productos.map((producto) => {
                const { rating, reviews } = generateRandomReviews();

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    key={producto.producto_id}
                    sx={{ maxWidth: { xs: "100%", sm: "45%" } }}
                  >
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "16px",
                        position: "relative", // Para posicionar el logo
                      }}
                    >
                      <Link
                        component={RouterLink}
                        to={`/producto/${producto.modelo.replace(/\//g, "-")}`}
                        underline="none"
                        sx={{ textDecoration: "none", color: "inherit" }}
                      >
                        {/* Logo de la marca en la parte superior derecha */}
                        {producto.marca_logo && (
                          <Box
                            component="img"
                            src={producto.marca_logo}
                            alt={producto.marca}
                            sx={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              height: 40,
                              backgroundColor: "#fff",
                              borderRadius: "5%",
                              padding: "5px",
                              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                        )}

                        <CardMedia
                          component="img"
                          height="220"
                          image={
                            producto.img_portada ||
                            "https://via.placeholder.com/300"
                          }
                          alt={producto.titulo}
                          sx={{ objectFit: "contain", padding: "10px" }}
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            noWrap
                          >
                            {producto.titulo}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <Rating
                              name={`rating-${producto.producto_id}`}
                              value={parseFloat(rating)}
                              precision={0.5}
                              readOnly
                              size="small"
                            />
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ ml: 1 }}
                            >
                              ({reviews})
                            </Typography>
                          </Box>
                          <Typography
                            variant="h5"
                            color="primary"
                            gutterBottom
                            sx={{ fontSize: "24px", fontWeight: "bold" }}
                          >
                            {formatPrice(producto.precios?.precio_lista || 0)}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            gutterBottom
                          >
                            <s>
                              {formatPrice(
                                (producto.precios?.precio_lista || 0) * 1.2
                              )}
                            </s>
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            Hasta 12 meses de{" "}
                            {formatPrice(
                              (producto.precios?.precio_lista || 0) / 12
                            )}
                          </Typography>
                          {producto.peso < 30 && (
                            <Typography variant="body2" color="text.success">
                              <LocalShipping
                                sx={{ verticalAlign: "middle", mr: 1 }}
                              />
                              Envío Gratis
                            </Typography>
                          )}
                          <Typography variant="body2" color="text.secondary">
                            Marca: {producto.marca}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Existencias: {producto.total_existencia}
                          </Typography>
                        </CardContent>
                      </Link>
                      <CardActions
                        sx={{ justifyContent: "space-between", paddingX: 2 }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<ShoppingCart />}
                          size="small"
                          sx={{ minWidth: "auto" }}
                        >
                          Agregar
                        </Button>
                        <IconButton
                          color="primary"
                          aria-label="add to favorites"
                          size="small"
                        >
                          <Favorite />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <Typography>No se encontraron productos.</Typography>
            )}
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={totalPaginas}
              page={pagina}
              onChange={handleChangePagina}
              color="primary"
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default Search;
