import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Typography,
  Container,
  Box,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Button,
  Stack,
  Grid,
} from "@mui/material";
import Slider from "react-slick";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import TerrainIcon from "@mui/icons-material/Terrain";
import PaidIcon from "@mui/icons-material/Paid";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const EventList = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [latestEvent, setLatestEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsData = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
      }));

      const eventsWithDate = eventsData.filter((event) => event.dateTime);
      setEvents(eventsWithDate);
      if (eventsWithDate.length > 0) {
        const sortedEvents = eventsWithDate.sort(
          (a, b) => b.dateTime - a.dateTime
        );
        setLatestEvent(sortedEvents[0]);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Container sx={{ mt: 3, mb: 6 }}>
      {latestEvent && (
        <Card
          sx={{
            mb: 4,
            backgroundColor: "#1976d2",
            color: "white",
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0 6px 25px rgba(0, 0, 0, 0.15)",
            },
          }}
          onClick={() => handleEventClick(latestEvent.id)}
        >
          <CardMedia
            component="img"
            image={latestEvent.photo}
            alt={`Foto del evento ${latestEvent.id}`}
            sx={{ width: "30%", objectFit: "cover" }}
          />
          <CardContent>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mb: 1, color: "#fff" }}
            >
              {latestEvent.eventName}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, color: "#fff" }}>
              {latestEvent.dateTime?.toLocaleDateString()}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, color: "#fff" }}>
              {latestEvent.creatorName}
            </Typography>
            <Chip
              icon={
                latestEvent.price && latestEvent.price > 0 ? (
                  <PaidIcon />
                ) : (
                  <FreeBreakfastIcon />
                )
              }
              label={
                latestEvent.price && latestEvent.price > 0
                  ? `$${latestEvent.price}`
                  : "Gratuito"
              }
              size="small"
              sx={{
                backgroundColor: "#fff",
                color: "#1976d2",
                fontWeight: "bold",
              }}
            />
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default EventList;
