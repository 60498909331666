import React, { useState, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Paper,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const TeamMembers = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const teamDocRef = doc(db, "Teams", uid);
        const unsubscribe = onSnapshot(teamDocRef, async (teamDocSnap) => {
          if (teamDocSnap.exists()) {
            const teamData = teamDocSnap.data();
            const membersData = await Promise.all(
              teamData.members.map(async (memberUid) => {
                const memberDocRef = doc(db, "Users", memberUid);
                const memberDocSnap = await getDoc(memberDocRef);
                return memberDocSnap.exists()
                  ? { uid: memberUid, ...memberDocSnap.data() }
                  : null;
              })
            );
            setMembers(membersData.filter((member) => member !== null));
          } else {
            console.error("No such team!");
          }
          setLoading(false);
        });

        return () => unsubscribe(); // Cleanup the listener on unmount
      } catch (error) {
        console.error("Error fetching team members:", error);
        setLoading(false);
      }
    };

    fetchTeamMembers();
  }, [uid]);

  const handleMemberClick = (memberUid) => {
    navigate(`/user/${memberUid}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          mb: 4,
          color: "#333333",
        }}
      >
        Miembros del Equipo
      </Typography>
      <List sx={{ px: { xs: 2, sm: 4, md: 6 }, py: 2 }}>
        {members.length > 0 ? (
          members.map((member, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleMemberClick(member.uid)}
              sx={{
                borderRadius: 2,
                mb: 2,
                px: 2,
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s ease-in-out, box-shadow 0.2s",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                  transform: "translateY(-4px)",
                  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt={member.name}
                  src={member.photoUrl}
                  sx={{ width: 56, height: 56, mr: 2 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={member.name}
                secondary={member.nickName}
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color: "#333333",
                }}
                secondaryTypographyProps={{
                  color: "textSecondary",
                  mt: 0.5,
                }}
              />
            </ListItem>
          ))
        ) : (
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ textAlign: "center", mt: 2 }}
          >
            No hay miembros en el equipo.
          </Typography>
        )}
      </List>
    </>
  );
};

export default TeamMembers;
