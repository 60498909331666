import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

export class Users {
  usersCollectionRef = collection(db, "Users");

  async getUserByUid(uid) {
    try {
      const userDocRef = doc(this.usersCollectionRef, uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        console.log("No such user!");
        return null;
      }
    } catch (error) {
      console.error("Error getting user:", error);
    }
  }
}

export class Events {
  eventsCollectionRef = collection(db, "events");

  async getEventsByEventManagerUid(uid) {
    try {
      if (!uid) return;
      const q = query(this.eventsCollectionRef, where("creatorUID", "==", uid));
      const querySnapshot = await getDocs(q);
      const events = [];
      querySnapshot.forEach((doc) => {
        events.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      return events;
    } catch (error) {
      console.error("Error getting events:", error);
      throw error;
    }
  }

  async createEvent(user, eventData) {
    try {
      // Subir la foto a Storage
      const storageRef = ref(
        storage,
        `event_images/${eventData.id}/${eventData.photo?.name}`
      );
      if (eventData.photo) {
        await uploadBytesResumable(storageRef, eventData.photo);
      }
      const photoUrl = eventData.photo ? await getDownloadURL(storageRef) : "";

      await addDoc(this.eventsCollectionRef, {
        creatorUID: eventData.creatorUID,
        eventName: eventData.eventName,
        dateTime: eventData.dateTime,
        location: eventData.location,
        photo: photoUrl,
        description: eventData.description,
        skillLevel: eventData.skillLevel,
        distance: eventData.distance,
        price: parseFloat(eventData.price),
        participants: [user.uid], // Incluir el UID del creador por defecto
      });
      console.log("Evento añadido!");
    } catch (error) {
      console.error("Error al crear evento:", error);
    }
  }

  async updateEvent(eventId, updatedData) {
    try {
      console.log(1, updatedData);
      const docRef = doc(this.eventsCollectionRef, eventId);

      // If there's a new photo to upload
      if (updatedData.photo) {
        const storageRef = ref(
          storage,
          `event_images/${eventId}/${updatedData.photo.name}`
        );
        await uploadBytesResumable(storageRef, updatedData.photo);
        const photoUrl = await getDownloadURL(storageRef);
        updatedData.photo = photoUrl; // Update the photo URL in the updatedData
      } else {
        delete updatedData.photo; // Remove photo field if not being updated
      }

      console.log(2, updatedData);

      await updateDoc(docRef, updatedData);
      console.log("Event updated!");
    } catch (error) {
      console.error("Error updating event:", error);
    }
  }

  async getEventById(eventId) {
    try {
      const docRef = doc(this.eventsCollectionRef, eventId);
      const eventDoc = await getDoc(docRef);

      if (eventDoc.exists()) {
        return { id: eventDoc.id, ...eventDoc.data() };
        // return eventDoc.data();
      } else {
        console.log("No such event!");
        return null;
      }
    } catch (error) {
      console.log("Error getting event:", error);
    }
  }

  async deleteEvent(eventId) {
    try {
      const docRef = doc(this.eventsCollectionRef, eventId);
      const eventDoc = await getDoc(docRef);

      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        if (eventData.photo) {
          const photoRef = ref(storage, eventData.photo);
          await deleteObject(photoRef);
        }

        await deleteDoc(docRef);
        console.log("Event deleted!");
      } else {
        console.log("No such event!");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  }
}
