import React from "react";
import {
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Stack,
  CardActions,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import TerrainIcon from "@mui/icons-material/Terrain";
import PaidIcon from "@mui/icons-material/Paid";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";

const EventInfoCard = ({ event, actionButtons }) => {
  return (
    <Card>
      {event.photo ? (
        <CardMedia
          sx={{ height: 200 }}
          image={event.photo ? event.photo : ""}
          title={`Foto del evento ${event.id}`}
        />
      ) : (
        <Box sx={{ height: 200, bgcolor: "#EDEDED" }}></Box>
      )}
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant="body1" component="div">
            {event.eventName}
          </Typography>
          <Chip
            icon={
              event.price && event.price > 0 ? (
                <PaidIcon />
              ) : (
                <FreeBreakfastIcon />
              )
            }
            label={
              event.price && event.price > 0 ? `$${event.price}` : "Gratuito"
            }
            size="small"
            color={event.price && event.price > 0 ? "secondary" : "primary"}
          />
        </Stack>
        <Stack direction="row" alignItems="center" mb={0.5}>
          <EventIcon fontSize="small" sx={{ mr: 0.5, color: "gray" }} />
          <Typography variant="caption" color="textSecondary">
            {new Date(event.dateTime).toLocaleString()}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" mb={0.5}>
          <TerrainIcon fontSize="small" sx={{ mr: 0.5, color: "gray" }} />
          <Typography variant="caption" color="textSecondary">
            Nivel: {event.skillLevel}
          </Typography>
        </Stack>
        {actionButtons ? <CardActions>{actionButtons}</CardActions> : null}
      </CardContent>
    </Card>
  );
};

export default EventInfoCard;
