import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext"; // Importa el contexto de autenticación
import { db } from "../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  Button,
  Container,
  Typography,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";

const RiderLocation = () => {
  const { user } = useUserAuth(); // Obtiene el usuario autenticado del contexto
  const [isTrackingEnabled, setIsTrackingEnabled] = useState(false);

  useEffect(() => {
    let intervalId;

    if (isTrackingEnabled) {
      // Comenzar a rastrear la ubicación del usuario
      intervalId = setInterval(() => {
        navigator.geolocation.getCurrentPosition(
          (position) => saveLocationToFirebase(position, user.uid), // Pasar el UID del usuario
          (error) => console.error("Error al obtener la ubicación:", error),
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
        );
      }, 900); // Actualizar la ubicación cada 3 segundos
    } else {
      // Detener el rastreo de ubicación si está desactivado
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
  }, [isTrackingEnabled, user.uid]); // Agregar user.uid como dependencia

  const handleTrackingToggle = () => {
    setIsTrackingEnabled((prevValue) => !prevValue);
  };

  const saveLocationToFirebase = async (position, uid) => {
    try {
      const { latitude, longitude } = position.coords;

      // Calcular la velocidad en km/h
      const speedKmph = (position.coords.speed || 0) * 1;

      // Guardar la ubicación en Firestore usando el UID del usuario
      const userDocRef = doc(db, "Users", uid); // Utiliza el UID del usuario y la colección "Users"

      await setDoc(
        userDocRef,
        {
          location: {
            latitude,
            longitude,
            timestamp: new Date(),
            speed: speedKmph, // Almacena la velocidad en km/h
          },
        },
        { merge: true },
      ); // Usar merge: true para combinar los datos en lugar de sobrescribirlos

      console.log("Ubicación del rider y velocidad guardadas en Firebase.");
    } catch (error) {
      console.error(
        "Error al guardar la ubicación y velocidad en Firebase:",
        error,
      );
    }
  };

  return (
    <Container>
      <Box mt={2}>
        <Typography variant="h6">Rider Location</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={isTrackingEnabled}
              onChange={handleTrackingToggle}
            />
          }
          label={
            isTrackingEnabled
              ? "Desactivar Rider Location"
              : "Activar Rider Location"
          }
        />
      </Box>
      {isTrackingEnabled && (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTrackingToggle}
          >
            Detener seguimiento
          </Button>
        </Box>
      )}
      <Typography variant="h8">
        Para obtener el máximo rendimiento de Rider Location no cierres la
        aplicación y quédate en esta pestaña. Rider Location se usará en
        carreras con mapas interactivos. Te recomendamos llenar tus datos en la
        pestaña de perfil para que aparezcan correctamente tu nombre y
        fotografía en el mapa en vivo.
      </Typography>
    </Container>
  );
};

export default RiderLocation;
