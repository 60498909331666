import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Avatar,
  Chip,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
  Container,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

const MarketDashboard = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (user) {
      fetchProducts(user.uid);
    }
  }, [user]);

  const fetchProducts = async (uid) => {
    const productsRef = collection(db, "Products");
    const q = query(productsRef, where("uid", "==", uid));
    onSnapshot(q, async (snapshot) => {
      const loadedProducts = await Promise.all(
        snapshot.docs.map(async (productDoc) => {
          const productData = productDoc.data();
          const userDoc = await getDoc(doc(db, "Users", productData.uid));
          const userData = userDoc.exists() ? userDoc.data() : {};
          const categoryDoc = await getDoc(
            doc(db, "Categories", productData.category)
          );
          const categoryData = categoryDoc.exists() ? categoryDoc.data() : {};
          return {
            id: productDoc.id,
            ...productData,
            user: userData,
            categoryName: categoryData.type || "Unknown Category",
          };
        })
      );
      setProducts(loadedProducts);
    });
  };

  const handleEditClick = (productId) => {
    navigate(`/createproduct?edit=${productId}`);
  };

  const handleDeleteClick = async (productId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este anuncio?")) {
      try {
        const productRef = doc(db, "Products", productId);
        const productSnapshot = await getDoc(productRef);
        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();

          // Delete main image
          if (productData.mainImageUrl) {
            const mainImageRef = ref(storage, productData.mainImageUrl);
            await deleteObject(mainImageRef);
          }

          // Delete additional images
          if (productData.moreImages && productData.moreImages.length > 0) {
            for (const imageUrl of productData.moreImages) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          }

          // Delete the product document
          await deleteDoc(productRef);
        }

        fetchProducts(user.uid); // Refresh products list after deletion
      } catch (error) {
        console.error("Error deleting product and images: ", error);
      }
    }
  };

  const handleToggleAvailability = async (productId, currentStatus) => {
    const newStatus = !currentStatus;
    try {
      const productRef = doc(db, "Products", productId);
      await updateDoc(productRef, {
        active: newStatus,
      });
      fetchProducts(user.uid); // Refresh products list after status change
    } catch (error) {
      console.error("Error updating product status: ", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <Box mb={3} textAlign="center">
        <Typography variant="h4" gutterBottom align="center">
          Mis Anuncios
        </Typography>
        <TextField
          placeholder="Buscar productos..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth={!isMobile}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            maxWidth: "100%",
            mx: "auto",
            backgroundColor: "#fff",
            borderRadius: 3,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ddd",
              },
              "&:hover fieldset": {
                borderColor: "#aaa",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#000",
              },
            },
          }}
        />
      </Box>

      <Grid container spacing={3}>
        {filteredProducts.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                borderRadius: 3,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: product.active ? "inherit" : "#f0f0f0",
              }}
            >
              <CardActionArea
                onClick={() => navigate(`/product/${product.id}`)}
                sx={{ opacity: product.active ? 1 : 0.6 }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={product.mainImageUrl}
                  alt={product.name}
                  sx={{ objectFit: "cover" }}
                />
                <CardContent sx={{ p: 2 }}>
                  {product.user && (
                    <Box display="flex" alignItems="center" mb={2}>
                      <Avatar
                        src={product.user.photoUrl || ""}
                        alt={product.user.nickname || ""}
                        sx={{ mr: 2, width: 40, height: 40 }}
                      />
                      <Box>
                        <Typography variant="body1" fontWeight="bold">
                          {product.user.name || "Unknown User"}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          fontStyle="italic"
                        >
                          {product.user.nickname || ""}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Typography variant="h6" fontWeight="bold">
                    {product.name}
                  </Typography>
                  <Box display="flex" alignItems="center" color="textSecondary">
                    <LocationOnIcon fontSize="small" />
                    <Typography variant="body1" color="textSecondary">
                      {product.city}, {product.state}, {product.country}
                    </Typography>
                  </Box>
                  <Chip
                    label={product.categoryName}
                    clickable
                    sx={{
                      mt: 1,
                      backgroundColor: "#000",
                      color: "#fff",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                  >
                    <Typography variant="body2" color="textSecondary">
                      Posted on: {moment(product.postDate).format("LL")}
                    </Typography>
                    <Chip
                      label={`Price: ${product.price}`}
                      sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    />
                  </Box>
                </CardContent>
              </CardActionArea>
              <Box mt={1} p={2} display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => handleEditClick(product.id)}
                  sx={{ mr: 1, flexGrow: 1 }}
                >
                  Editar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDeleteClick(product.id)}
                  sx={{ flexGrow: 1 }}
                >
                  Eliminar
                </Button>
                <Button
                  variant="contained"
                  color={product.active ? "error" : "success"}
                  onClick={() =>
                    handleToggleAvailability(product.id, product.active)
                  }
                  sx={{ ml: 1, flexGrow: 1 }}
                >
                  {product.active ? "Desactivar" : "Activar"}
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MarketDashboard;
