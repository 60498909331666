import React from "react";

import Header from "../components/Home/Header";
import Footer from "../components/Home/Footer";
import ProductSlider from "./Front/ProductSlider";

const Welcome = () => {
  return (
    <>
      <Header />
      <ProductSlider />
      <Footer />
    </>
  );
};

export default Welcome;
