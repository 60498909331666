import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Rating,
  Avatar, // Import Avatar component from Material-UI
} from "@mui/material";
import OpenIcon from "@mui/icons-material/FiberManualRecord"; // This icon can represent an open status
import LocationOnIcon from "@mui/icons-material/LocationOn"; // This icon can represent the location

function WorkshopCard() {
  // This is a placeholder for the rating value. You should replace it with a state or props.
  const ratingValue = 4;

  return (
    <Card sx={{ maxWidth: 345, borderRadius: "16px", position: "relative" }}>
      <CardMedia
        component="img"
        height="140"
        image="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Screenshot%202024-03-14%20at%2018.50.47.png?alt=media&token=d2204ef2-948f-45d4-80c2-10f76757142d" // Replace with your actual image path
        alt="Double Black Workshop"
      />
      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          bgcolor: "green",
          color: "white",
          borderRadius: "4px",
          p: "2px 8px",
        }}
      >
        <Typography variant="caption">Abierto</Typography>
      </Box>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Avatar
            src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/db.jpeg?alt=media&token=913acbd6-06b9-4cfc-bea9-8c3fff051b71"
            alt="DBW Logo"
            sx={{ width: 64, height: 64, marginRight: "8px" }}
          />{" "}
          {/* Replace with your actual logo path */}
          <Typography gutterBottom variant="h5" component="div">
            Double Black Workshop
          </Typography>
        </Box>
        <Rating name="read-only" value={ratingValue} readOnly />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnIcon color="action" />
          <Typography variant="subtitle1" color="text.secondary">
            Ubicacion: Hermosillo, Son
          </Typography>
        </Box>
      </CardContent>
      <Box sx={{ p: 2 }}>
        <Button variant="contained" fullWidth>
          Agendar Servicio
        </Button>
      </Box>
    </Card>
  );
}

export default WorkshopCard;
