import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Alert,
  Container,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const SelectBikeCard = ({ setSelectedBike }) => {
  const { user } = useUserAuth();
  const [bikes, setBikes] = useState([]);
  const [selectedBikeId, setSelectedBikeId] = useState(null); // Estado para el ID de la bicicleta seleccionada
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.uid) {
      const firestore = getFirestore();
      const q = query(
        collection(firestore, "Bikes"),
        where("userid", "==", user.uid),
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const bikesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBikes(bikesData);
      });

      return () => unsubscribe();
    }
  }, [user]);

  const handleAddBike = () => {
    navigate("/addBike");
  };

  const handleSelectBike = (bikeId) => {
    setSelectedBikeId(bikeId); // Al seleccionar una bicicleta, establece su ID como la seleccionada
    setSelectedBike(bikes.find((bike) => bike.id === bikeId)); // Envía toda la información de la bicicleta seleccionada al componente padre
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Selecciona tu bicicleta que requiere servicio
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleAddBike}
        size="small" // Tamaño más pequeño para el botón
        sx={{ marginBottom: "10px" }} // Agregar margen inferior al botón
      >
        Agregar Nueva Bicicleta
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
      <Grid container spacing={3}>
        {bikes.map((bike) => (
          <Grid item xs={12} sm={6} md={4} key={bike.id}>
            <Card
              sx={{
                borderRadius: "8px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: selectedBikeId === bike.id ? "#e6ffe6" : "", // Color verde claro si es la bicicleta seleccionada
              }}
              onClick={() => handleSelectBike(bike.id)} // Maneja la selección de la bicicleta al hacer clic en cualquier parte del componente
            >
              <CardMedia
                component="img"
                height="140" // Altura para mostrar la imagen completa
                image={bike.bikePic}
                alt={bike.bikeBrand}
                sx={{
                  objectFit: "contain", // Ajustar imagen para que quepa completamente
                  borderRadius: "8px 8px 0 0",
                }}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {bike.bikeBrand}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Tipo: {bike.bikeType}
                  <br />
                  Tamaño del Cuadro: {bike.bikeSize}
                  <br />
                  Tamaño de la Rueda: {bike.wheelSize}
                </Typography>
              </CardContent>
              <Button
                variant="contained" // Cambiar a variant="contained" para que el botón se rellene
                color={selectedBikeId === bike.id ? "secondary" : "primary"} // Cambiar a color="secondary" cuando esté seleccionada
                fullWidth
                size="large" // Tamaño más grande para el botón
              >
                {selectedBikeId === bike.id
                  ? "Bicicleta Seleccionada"
                  : "Seleccionar Bicicleta"}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SelectBikeCard;
