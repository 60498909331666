import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import Typed from "typed.js";
import algoliasearch from "algoliasearch/lite";
import { useNavigate } from "react-router-dom";

const AlgoliaSearch = () => {
  const [query, setQuery] = useState("");
  const [isTypedActive, setIsTypedActive] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const typedElement = useRef(null);
  const typedInstance = useRef(null);
  const navigate = useNavigate();

  const client = algoliasearch(
    "CE2DHOJRGG",
    "b8c31052f535789944e9689e8f549bb4"
  );
  const index = client.initIndex("radeo");

  useEffect(() => {
    if (isTypedActive) {
      typedInstance.current = new Typed(typedElement.current, {
        strings: [
          "Cámara inteligente",
          "Router WIFI",
          "Taladro",
          "Paneles solares",
        ],
        typeSpeed: 100,
        backSpeed: 50,
        loop: true,
        showCursor: false,
      });
    }
    return () => {
      if (typedInstance.current) {
        typedInstance.current.destroy();
      }
    };
  }, [isTypedActive]);

  const handleSearch = () => {
    if (query.trim()) {
      index
        .search(query, {
          attributesToRetrieve: [
            "Título",
            "Modelo",
            "Imagen Principal",
            "Marca",
          ],
        })
        .then(({ hits }) => {
          setSearchResults(hits);
        })
        .catch((err) => {
          console.error("Error buscando en Algolia:", err);
        });
    } else {
      setSearchResults([]);
    }
  };

  const handleInputFocus = () => {
    if (isTypedActive) {
      setIsTypedActive(false);
      setQuery("");
    }
  };

  const handleResultClick = (id) => {
    navigate(`/producto/${id}`);
    setSearchResults([]);
  };

  const handleClearSearch = () => {
    setQuery("");
    setSearchResults([]);
    setIsTypedActive(true);
  };

  const handleSearchRedirect = () => {
    if (query.trim()) {
      navigate(`/search/${query}`);
      setSearchResults([]); // Limpiar los resultados al hacer la redirección
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchRedirect(); // Redirige al presionar Enter
    }
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: "100%", position: "relative" }}>
      <TextField
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          handleSearch();
        }}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown} // Agrega el evento para detectar Enter
        placeholder="¿Qué estás buscando?"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          input: { padding: "10px", fontSize: "1rem" },
          backgroundColor: "white",
          borderRadius: "4px",
          maxWidth: { xs: "100%", md: "500px" },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#27a063",
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearchRedirect}>
                <SearchIcon sx={{ color: "#232f3e" }} />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: query && (
            <InputAdornment position="start">
              <IconButton onClick={handleClearSearch} edge="end">
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={typedElement}
      />

      {searchResults.length > 0 && (
        <List
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            bgcolor: "white",
            zIndex: 10,
            boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
            maxHeight: "60vh",
            overflowY: "auto",
            border: "1px solid #ddd",
            "@media (max-width: 600px)": {
              maxHeight: "calc(100vh - 56px)",
              position: "fixed",
              top: "56px",
            },
          }}
        >
          {searchResults.map((result) => (
            <ListItem
              key={result.objectID}
              button
              onClick={() => handleResultClick(result.objectID)}
              sx={{
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={result["Imagen Principal"]}
                  alt={result.Título}
                  variant="square"
                  sx={{ width: 50, height: 50, marginRight: 2 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={result.Título}
                secondary={`${result.Marca} - ${result.Modelo}`}
                primaryTypographyProps={{
                  fontWeight: "bold",
                  color: "black",
                }}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default AlgoliaSearch;
