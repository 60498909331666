import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Pagination,
} from "@mui/material";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ITEMS_PER_PAGE = 5;

const Pings = ({ pingsData }) => {
  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedPings = pingsData.slice(startIndex, endIndex);

  return (
    <Box>
      <List>
        {paginatedPings.map((ping, index) => (
          <ListItem key={index} sx={{ borderBottom: "1px solid #ccc" }}>
            <ListItemText
              secondary={
                <>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Tiempo: {moment(ping.time).format("MM-DD HH:mm")}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Temperatura: {ping.temperature} °C
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    Voltaje: {ping.vsysVoltage.toFixed(2)} V
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
      <Pagination
        count={Math.ceil(pingsData.length / ITEMS_PER_PAGE)}
        page={page}
        onChange={handleChangePage}
        sx={{ mt: 2, display: "flex", justifyContent: "center" }}
      />
    </Box>
  );
};

export default Pings;
