import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Modelos especificados
const modelos = ["CS-DB2KIT", "CS-H8C", "CS-DL05-R100", "T30"];

const ProductDetail = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fetchedProducts = [];
        for (const modelo of modelos) {
          const productRef = doc(db, "products", modelo);
          const productSnapshot = await getDoc(productRef);

          if (productSnapshot.exists()) {
            const productData = productSnapshot.data();
            fetchedProducts.push({
              modelo: modelo,
              ...productData,
            });
          } else {
            console.error(`Producto con Modelo ${modelo} no encontrado.`);
          }
        }
        setProducts(fetchedProducts);
      } catch (err) {
        console.error("Error al buscar los productos: ", err);
        setError("Hubo un problema al buscar los productos.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleProductClick = (modelo) => {
    navigate(`/producto/${modelo}`);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <Box sx={{ padding: "20px", backgroundColor: "#fff" }}>
      {/* Título y categoría */}
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "10px", textAlign: "center" }}
      >
        Hogar Inteligente
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "regular",
          marginBottom: "30px",
          textAlign: "center",
        }}
      >
        Categoría: Home Automation
      </Typography>

      {/* Productos */}
      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid
            item
            xs={6} // 2 productos por fila en pantallas pequeñas
            sm={6} // 2 productos por fila en tablets
            md={3} // 4 productos por fila en pantallas medianas/grandes
            key={product.modelo}
            onClick={() => handleProductClick(product.modelo)}
            sx={{ cursor: "pointer" }}
          >
            <Card
              sx={{
                borderRadius: "12px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              {/* Imagen del producto */}
              <CardMedia
                component="img"
                height="180"
                image={product["Imagen Principal"]}
                alt={product.Modelo}
                sx={{ objectFit: "contain", padding: "10px" }}
              />

              {/* Detalles del producto */}
              <CardContent sx={{ textAlign: "center" }}>
                {/* Título del producto truncado */}
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold", fontSize: ".8rem" }}
                >
                  {truncateText(product.Título, 50)}
                </Typography>

                {/* Modelo */}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    marginTop: "5px",
                    fontSize: "1rem",
                  }}
                >
                  Modelo: {product.Modelo}
                </Typography>

                {/* Precio */}
                <Typography
                  variant="h6"
                  sx={{ color: "#000", marginTop: "10px" }}
                >
                  {product["Precio Lista"]}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductDetail;
